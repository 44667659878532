import mapOffer from 'api/mappings/mapOffer';

import EFinanceTypes from 'types/enums/EFinanceTypes';
import EFixedDownPaymentRates from 'types/enums/EFixedDownPaymentRates';
import EMachines from 'types/enums/EMachines';
import EPaymentForms from 'types/enums/EPaymentForms';
import { IApiOffer, IOffer } from 'types/interfaces/IOffer';
import { ISimulatorValues } from 'types/interfaces/ISimulator';
import { SimulatorState } from 'types/interfaces/ISimulatorState';

import formatNumber from '../formatNumber';
import getMachinePriceMaxForCbyo from '../getMachinePriceMaxForCbyo';
import getMachinePriceMinForCbyo from '../getMachinePriceMinForCbyo';
import Calculator from './calculator';
import checkForBalloon, {
  checkForInterestBasedBalloon,
} from './utils/checkForBalloon';
import checkForResidualValue from './utils/checkForResidualValue';
import getBalloonOrResidualValue from './utils/getBalloonOrResidualValue';
import getDownPaymentMinMax from './utils/getDownPaymentMinMax';
import getResultAmount from './utils/getResultAmount';
import getTermDependencies from './utils/getTermDependencies';

// @Todo this file has to be modularized

// @Todo move these 4 enums to types
export enum ERadioOptions {
  FINANCE_TYPE = 'financeType',
  PAYMENT_FORM = 'paymentForm',
}

export enum ESelectOptions {
  MACHINE = 'machine',
}

export enum ESliderOptions {
  ADVANCED_PAYMENTS = 'advancePayments',
  BALLOON = 'balloon',
  DOWN_PAYMENT = 'downPayment',
  FOLLOWING_PAYMENTS = 'followingPayments',
  MACHINE_PRICE = 'machinePrice',
  OPERATING_HOURS = 'operatingHours',
  RESIDUAL_VALUE = 'residualValue',
  TERM = 'term',
  INTEREST_RATE = 'interestRate',
}

export enum EAdditionalOptions {
  UPSELLING = 'upselling',
}

const periodInYear: { [key in EPaymentForms]: number } = {
  [EPaymentForms.MONTHLY]: 12,
  [EPaymentForms.QUARTERLY]: 4,
  [EPaymentForms.SEMIANNUALLY]: 2,
  [EPaymentForms.ANNUALLY]: 1,
};
const getAdvancedPaymentsOptions = (
  offer: Pick<IOffer, 'durationMax'>,
  simulator: Pick<SimulatorState, ERadioOptions.PAYMENT_FORM>, // from state
  value?: number, // query param (`advancePayments`) or `advancePayments` from state
): SimulatorState['sliderOptions']['advancePayments'] => ({
  name: ESliderOptions.ADVANCED_PAYMENTS,
  label: 'SIMULATOR.ADVANCE_PAYMENT',
  step: 1,
  min: 1,
  max: Math.min(
    8,
    Number(offer.durationMax) *
      periodInYear[simulator?.paymentForm || EPaymentForms.MONTHLY] -
      1,
  ),
  value: value || 1,
});

const getBalloonSliderOptions = (
  offer: Pick<IOffer, 'balloonPeriod' | 'durationMin' | 'durationMax'> & {
    country: Pick<IOffer['country'], 'calculateRvWithNet'>;
  } & {
    interestBasedOnDownPayment: Pick<
      Required<IOffer>['interestBasedOnDownPayment'],
      'interestBasedBalloon'
    >;
  },
  simulator: Pick<
    SimulatorState,
    | ESelectOptions.MACHINE
    | ESliderOptions.MACHINE_PRICE
    | ESliderOptions.DOWN_PAYMENT
    | ESliderOptions.BALLOON
    | ESliderOptions.TERM
  >,
  value?: number, // query param (`balloon`) or `balloon` from state
): SimulatorState['sliderOptions']['balloon'] => {
  if (!simulator.term) {
    return undefined;
  }
  const amount = getResultAmount(
    simulator?.machinePrice,
    simulator?.downPayment,
  );
  const offerInterestBasedBalloon =
    offer.interestBasedOnDownPayment.interestBasedBalloon;
  if (offerInterestBasedBalloon && simulator.machine) {
    const balloonValue = getBalloonOrResidualValue(
      offer.country.calculateRvWithNet,
      simulator?.machinePrice,
      amount,
      offerInterestBasedBalloon.balloonValuesPerYear[simulator.term][
        simulator.machine
      ],
    );
    return {
      name: ESliderOptions.BALLOON,
      label: 'SIMULATOR.BALLOON',
      step: 500,
      disabled: true,
      min: 0,
      max: balloonValue * 2,
      value: balloonValue,
    };
  }
  const balloonMinValue = getBalloonOrResidualValue(
    offer.country.calculateRvWithNet,
    simulator?.machinePrice,
    amount,
    offer.balloonPeriod[simulator.term][1],
  );
  const balloonMaxValue = getBalloonOrResidualValue(
    offer.country.calculateRvWithNet,
    simulator?.machinePrice,
    amount,
    offer.balloonPeriod[simulator.term][2],
  );
  let balloonValue = value || balloonMinValue;
  // @Todo see ticket #204 - until then go with this way easier approach
  // getBalloonOrResidualValue(
  //   offer.country.calculateRvWithNet,
  //   simulator?.machinePrice,
  //   amount,
  //   value ||
  //     // @Todo does it make sense to calculate the value with the same value as dependency?
  //     simulator.balloon ||
  //     offer.balloonPeriod[
  //       simulator.term ||
  //         Math.round((offer.durationMin + offer.durationMax) / 2)
  //     ][3],
  // );
  if (balloonValue > balloonMaxValue) {
    balloonValue = balloonMaxValue;
  }
  if (balloonValue < balloonMinValue) {
    balloonValue = balloonMinValue;
  }
  return {
    name: ESliderOptions.BALLOON,
    label: 'SIMULATOR.BALLOON',
    step: 500,
    disabled: false,
    min: balloonMinValue,
    max: balloonMaxValue,
    value: balloonValue,
  };
};

const getDownPaymentSliderOptions = (
  offer: Pick<
    IOffer,
    'downPaymentMin' | 'downPaymentMax' | 'machinePriceMin' | 'machinePriceMax'
  >,
  simulator: Pick<
    SimulatorState,
    ESliderOptions.DOWN_PAYMENT | ESliderOptions.MACHINE_PRICE
  >,
  value?: number, // query param (`downpayment`) or `downPayment` from state
  valueFromRadioGroup?: EFixedDownPaymentRates,
): SimulatorState['sliderOptions']['downPayment'] => {
  const { min, max } = getDownPaymentMinMax(offer, simulator);
  let downPaymentValue = value;

  if (downPaymentValue && downPaymentValue < min) {
    downPaymentValue = min;
  } else if (downPaymentValue && downPaymentValue > max) {
    downPaymentValue = max;
  }

  return {
    name: ESliderOptions.DOWN_PAYMENT,
    label: 'SIMULATOR.DOWNPAYMENT',
    step: 500,
    min,
    max,
    value:
      (valueFromRadioGroup &&
        (simulator.machinePrice * Number(valueFromRadioGroup)) / 100) ||
      downPaymentValue ||
      simulator.downPayment || // @Todo this is always set - or maybe not? Check!
      min,
  };
};

const getFollowingPaymentsOptions = (
  offer: Pick<IOffer, 'durationMin' | 'durationMax'>,
  simulator: Pick<
    SimulatorState,
    ESliderOptions.ADVANCED_PAYMENTS | ERadioOptions.PAYMENT_FORM
  >, // from state
  value?: number, // query param (`followingPayments`) or `followingPayments` from state
): SimulatorState['sliderOptions']['followingPayments'] => ({
  name: ESliderOptions.FOLLOWING_PAYMENTS,
  label: 'SIMULATOR.FOLLOWING_NUMBER_PAYMENTS',
  step: 1,
  min: Math.max(
    0,
    periodInYear[simulator?.paymentForm || EPaymentForms.MONTHLY] *
      offer.durationMin -
      Number(simulator?.advancePayments || 1),
  ),
  max:
    periodInYear[simulator?.paymentForm || EPaymentForms.MONTHLY] *
      offer.durationMax -
    Number(simulator?.advancePayments || 1),
  value:
    value ||
    Math.round((offer.durationMin + offer.durationMax) / 2) *
      periodInYear[simulator?.paymentForm || EPaymentForms.MONTHLY] -
      (simulator?.advancePayments || 0),
});

// When downPayment is selectable via radio buttons,
// the machine price needs to be considered before it is calculated
// (radioOptions are getting calculated before sliderOptions)
const getMachinePrice = (
  offer: Pick<IOffer, 'machinePriceMin' | 'machinePriceMax'> & {
    country: Pick<IOffer['country'], 'code' | 'currency'>;
  },
  value?: number, // query param (`amount`) or `machinePrice` from state
): number => {
  const cbyoValue = localStorage.getItem('cbyoValue');
  // added cbyoValue as default value for machinePrice slider
  return (
    value ||
    Number(cbyoValue) ||
    Math.round((offer.machinePriceMin + offer.machinePriceMax) / 2 / 1000) *
      1000
  );
};

const getMachinePriceOptions = (
  offer: Pick<IOffer, 'machinePriceMin' | 'machinePriceMax'> & {
    country: Pick<IOffer['country'], 'code' | 'currency'>;
  },
  simulator: Pick<SimulatorState, ERadioOptions.FINANCE_TYPE>,
  value: number, // see machinePriceValue
): SimulatorState['sliderOptions']['machinePrice'] => {
  let machinePriceValue = value;
  // machinePriceMin and machinePriceMax is updated on CBYO offers with 50% of the cbyoValue
  const cbyoValue = Number(localStorage.getItem('cbyoValue'));
  let { machinePriceMin, machinePriceMax } = offer;

  if (cbyoValue) {
    machinePriceMin = getMachinePriceMinForCbyo(cbyoValue, machinePriceMin);
    machinePriceMax = getMachinePriceMaxForCbyo(cbyoValue, machinePriceMax);
  }

  if (machinePriceValue < machinePriceMin) {
    machinePriceValue = machinePriceMin;
  } else if (machinePriceValue > machinePriceMax) {
    machinePriceValue = machinePriceMax;
  }

  return {
    name: ESliderOptions.MACHINE_PRICE,
    label:
      simulator.financeType === EFinanceTypes.LEASING &&
      (offer.country.code.includes('PT') || offer.country.code.includes('ES'))
        ? 'SIMULATOR.MACHINE_PRICE_LEASING'
        : 'SIMULATOR.MACHINE_PRICE',
    step: 1000,
    min: machinePriceMin,
    max: machinePriceMax,
    value: machinePriceValue,
  };
};

const getOperatingHoursOptions = (
  offer: Pick<
    IOffer,
    'operatingHoursMin' | 'operatingHoursMax' | 'operatingHoursLabel'
  >,
  value?: number, // query param (`hours`) or `operatingHours` from state
): SimulatorState['sliderOptions']['operatingHours'] => ({
  name: ESliderOptions.OPERATING_HOURS,
  label: offer.operatingHoursLabel,
  step: 50,
  min: offer.operatingHoursMin,
  max: offer.operatingHoursMax,
  value:
    value ||
    Math.round((offer.operatingHoursMin + offer.operatingHoursMax) / 2 / 100) *
      100,
});

const getResidualValueOptions = (
  offer: Pick<IOffer, 'residualValuePeriod' | 'durationMin' | 'durationMax'> & {
    country: Pick<IOffer['country'], 'calculateRvWithNet'>;
  },
  simulator: Pick<
    SimulatorState,
    | ESliderOptions.MACHINE_PRICE
    | ESliderOptions.DOWN_PAYMENT
    | ESliderOptions.RESIDUAL_VALUE
    | ESliderOptions.TERM
  >,
  value?: number, // query param (`rv`) or `residualValue` from state
): SimulatorState['sliderOptions']['residualValue'] | undefined => {
  if (!simulator.term) {
    return undefined;
  }
  const amount = getResultAmount(
    simulator?.machinePrice,
    simulator?.downPayment,
  );
  const rvMinValue = getBalloonOrResidualValue(
    offer.country.calculateRvWithNet,
    simulator?.machinePrice,
    amount,
    offer.residualValuePeriod[simulator.term][1],
  );
  const rvMaxValue = getBalloonOrResidualValue(
    offer.country.calculateRvWithNet,
    simulator?.machinePrice,
    amount,
    offer.residualValuePeriod[simulator.term][2],
  );
  let rvValue = value || rvMinValue;
  // @Todo see ticket #204 - until then go with this way easier approach
  // getBalloonOrResidualValue(
  //   offer.country.calculateRvWithNet,
  //   simulator?.machinePrice,
  //   amount,
  //   value ||
  //     simulator.residualValue ||
  //     offer.residualValuePeriod[
  //       simulator.term ||
  //         Math.round((offer.durationMin + offer.durationMax) / 2)
  //     ][3],
  // );
  if (rvValue > rvMaxValue) {
    rvValue = rvMaxValue;
  }
  if (rvValue < rvMinValue) {
    rvValue = rvMinValue;
  }
  return {
    name: ESliderOptions.RESIDUAL_VALUE,
    label: 'SIMULATOR.RESIDUAL_VALUE',
    step: 500,
    min: rvMinValue,
    max: rvMaxValue,
    value: rvValue,
  };
};

const getTermOptions = (
  offer: Pick<IOffer, 'durationMin' | 'durationMax'>,
  simulator: Pick<
    SimulatorState,
    | ESliderOptions.ADVANCED_PAYMENTS
    | ESliderOptions.FOLLOWING_PAYMENTS
    | ERadioOptions.PAYMENT_FORM
  >,
  value?: number, // query param (`term`) or `term` from state
): SimulatorState['sliderOptions']['term'] => ({
  name: ESliderOptions.TERM,
  label: 'SIMULATOR.YEARS',
  step: 1,
  min: offer.durationMin,
  max: offer.durationMax,
  value:
    value ||
    Math.ceil(
      (Number(simulator.advancePayments || 0) +
        Number(simulator.followingPayments || 0)) /
        periodInYear[simulator.paymentForm || EPaymentForms.MONTHLY],
    ) ||
    Math.round((offer.durationMin + offer.durationMax) / 2),
});

const getInterestRateOptions = (
  offer: Pick<IOffer, 'interestRateMin' | 'interestRateMax'>,
  value: number,
): SimulatorState['sliderOptions']['interestRate'] => ({
  name: ESliderOptions.INTEREST_RATE,
  label: 'SIMULATOR.INTEREST_RATE',
  step: 0.25,
  min: offer.interestRateMin * 100,
  max: offer.interestRateMax * 100,
  value,
});

/**
 * Following values may affect the rendering result (ordered by velocity descending):
 * 1. URL Params
 *  - is optional (currently only provided when creating store = first render)
 *  - possible params: advancePayments, downpayment, followingPayments, amount (=machinePrice), hours (=operatingHours), rv (=residualValue), term, leasing (=financeType),
 * 2. local storage
 *  - is optional
 *  - should represent the last global state
 *  - if set, the setup of the initial state can be omitted (take local storage instead) frequency (=paymentForm)
 *  3. Some state properties may affect other properties (mutual dependency)
 *  - e.g. term affects balloon or residualValue
 *  4. The country
 *   - Some state properties are country specific
 * @param currentState
 * @param offer
 * @param queryParamValues
 */
export default (
  offer: IApiOffer,
  currentState?: SimulatorState,
  queryParamValues?: Partial<ISimulatorValues>,
): SimulatorState => {
  const mappedOffer: IOffer = mapOffer(offer);
  const downPaymentRadioValues: EFixedDownPaymentRates[] = [
    EFixedDownPaymentRates.PERCENT_10,
    EFixedDownPaymentRates.PERCENT_35,
    EFixedDownPaymentRates.PERCENT_50,
  ];
  const downPaymentRadioQueryParam: false | EFixedDownPaymentRates =
    // @ts-ignore as downPaymentRadioValues is typed, ts throws an error which can be safely ignored
    downPaymentRadioValues.includes(queryParamValues?.downPayment)
      ? // @ts-ignore
        (queryParamValues?.downPayment as EFixedDownPaymentRates)
      : false;
  const machinePriceValue = getMachinePrice(
    {
      machinePriceMin: mappedOffer.machinePriceMin,
      machinePriceMax: mappedOffer.machinePriceMax,
      country: mappedOffer.country,
    },
    queryParamValues?.amount || currentState?.sliderOptions.machinePrice.value,
  );
  /**
   * Radio options are:
   * 1. financeType
   *  query 'leasing'
   * 2. paymentForm
   *  query 'frequency'
   * 3. downPayment
   *  Mind: optional: if interestBasedOnDownPayment is set in offer
   *  The values from sliderOptions downPayment are still used but
   *  the actual value is controlled by the downPayment radio group.
   *  query 'downpayment'
   */
  const radioOptions: SimulatorState['radioOptions'] = {
    financeType: {
      name: ERadioOptions.FINANCE_TYPE,
      value:
        queryParamValues?.leasing ||
        currentState?.radioOptions.financeType.value ||
        mappedOffer.financeTypes[0],
      defaultValue: queryParamValues?.leasing || mappedOffer.financeTypes[0],
      items: mappedOffer.financeTypes.map(item => ({
        value: item,
        label: `SIMULATOR.FINANCE_TYPE_${
          item.indexOf('purchase') >= 0 ? 'INSTALLMENT' : item.toUpperCase()
        }`,
      })),
    },
    paymentForm: {
      name: ERadioOptions.PAYMENT_FORM,
      value:
        queryParamValues?.frequency ||
        currentState?.radioOptions.paymentForm.value ||
        mappedOffer.paymentForms[0],
      defaultValue: queryParamValues?.frequency || mappedOffer.paymentForms[0],
      items: mappedOffer.paymentForms.map(item => ({
        value: item,
        label: `SIMULATOR.FORM_OF_PAYMENT_${item.toUpperCase()}`,
      })),
    },
    ...(mappedOffer.interestBasedOnDownPayment && {
      downPayment: {
        name: ESliderOptions.DOWN_PAYMENT,
        value:
          downPaymentRadioQueryParam ||
          currentState?.radioOptions.downPayment?.value ||
          EFixedDownPaymentRates.PERCENT_35,
        defaultValue:
          downPaymentRadioQueryParam || EFixedDownPaymentRates.PERCENT_35,
        items: downPaymentRadioValues.map(dpRadioValue => ({
          value: dpRadioValue,
          label: `${dpRadioValue}% (${formatNumber(
            (machinePriceValue * Number(dpRadioValue)) / 100,
            mappedOffer.country.code,
            {
              withCurrency: true,
              currency: mappedOffer.country.currency,
            },
          )})`,
        })),
      },
    }),
  };

  /**
   * Select options are:
   * 1. machine
   *  -> dep:
   *     state 'paymentForm' === purchase
   *     country 'interestBasedOnDownPayment.interest_based_balloon'
   * --> Otherwise NOT SET!
   */
  const selectOptions: SimulatorState['selectOptions'] = {
    ...(mappedOffer.interestBasedOnDownPayment?.interestBasedBalloon &&
      radioOptions.financeType.value === EFinanceTypes.PURCHASE && {
        [ESelectOptions.MACHINE]: {
          name: ESelectOptions.MACHINE,
          defaultValue: EMachines.MACHINE_1,
          value:
            currentState?.selectOptions?.machine?.value || EMachines.MACHINE_1,
          items: Object.entries(
            mappedOffer.interestBasedOnDownPayment.interestBasedBalloon
              .machines,
          )
            .filter(([, machineName]) => !!machineName)
            .map(([machineKey, machineName]) => ({
              value: machineKey as EMachines,
              label: machineName,
            })),
        },
      }),
  };

  /**
   * Slider options are:
   * 1. advancePayments?
   *  -> dep:
   *    state 'paymentForm'
   *    country = 'en_UK'
   *    offer `firstPaymentAdvance` === true
   *  --> Otherwise NOT SET!
   *  query 'advancePayments'
   * 2. balloon?
   *  -> dep:
   *    state 'term' & 'result.showBalloon'
   *  --> Otherwise NOT SET!
   * 3. downPayment
   *  -> dep:
   *    state 'machinePrice'
   *  --> Otherwise NOT SET!
   *  query 'downpayment'
   * 4. followingPayments?
   *  -> dep:
   *    state 'paymentForm'
   *    country = 'en_UK'
   *    offer 'firstPaymentAdvance' === true
   *  --> Otherwise NOT SET!
   *  query 'followingPayments'
   * 5. machinePrice
   *  -> no deps => NEVER NULL!
   *  query 'amount'
   * 6. operatingHours
   *  -> no deps => NEVER NULL!
   *  query 'hours'
   * 7. residualValue?
   *  -> dep:
   *    state 'term' & 'result.showRV'
   *  --> Otherwise NOT SET!
   *  query 'rv'
   * 8. term?
   *  -> dep:
   *    see `getTermDependencies()`
   *  --> Otherwise NOT SET!
   *  query 'term'
   */
  const machinePrice: SimulatorState['sliderOptions']['machinePrice'] =
    getMachinePriceOptions(
      {
        machinePriceMin: mappedOffer.machinePriceMin,
        machinePriceMax: mappedOffer.machinePriceMax,
        country: mappedOffer.country,
      },
      {
        financeType: EFinanceTypes.PURCHASE,
      },
      machinePriceValue,
    );
  const operatingHours: SimulatorState['sliderOptions']['operatingHours'] =
    getOperatingHoursOptions(
      {
        operatingHoursMax: mappedOffer.operatingHoursMax,
        operatingHoursMin: mappedOffer.operatingHoursMin,
        operatingHoursLabel: mappedOffer.operatingHoursLabel,
      },
      queryParamValues?.hours ||
        currentState?.sliderOptions.operatingHours.value,
    );
  const downPayment: SimulatorState['sliderOptions']['downPayment'] =
    getDownPaymentSliderOptions(
      {
        // static value for special case interestBasedOnDownPayment, see also ZSimulatorDownPaymentRadioOptionValues
        downPaymentMax: mappedOffer.interestBasedOnDownPayment
          ? 0.5
          : mappedOffer.downPaymentMax,
        // static value for special case interestBasedOnDownPayment, see also ZSimulatorDownPaymentRadioOptionValues
        downPaymentMin: mappedOffer.interestBasedOnDownPayment
          ? 0.1
          : mappedOffer.downPaymentMin,
        machinePriceMax: mappedOffer.machinePriceMax,
        machinePriceMin: mappedOffer.machinePriceMin,
      },
      {
        downPayment: currentState?.sliderOptions.downPayment.value,
        machinePrice: machinePrice.value,
      },
      queryParamValues?.downPayment ||
        currentState?.sliderOptions.downPayment.value,
      radioOptions.downPayment?.value,
    );
  const advancePayments: SimulatorState['sliderOptions']['advancePayments'] =
    radioOptions.paymentForm.value &&
    ['en_uk'].includes(mappedOffer.country.code.toLowerCase()) &&
    mappedOffer.firstPaymentAdvance
      ? getAdvancedPaymentsOptions(
          {
            durationMax: mappedOffer.durationMax,
          },
          { paymentForm: radioOptions.paymentForm.value },
          queryParamValues?.advancePayments ||
            currentState?.sliderOptions.advancePayments?.value,
        )
      : undefined;
  const followingPayments: SimulatorState['sliderOptions']['followingPayments'] =
    radioOptions.paymentForm.value &&
    ['en_uk'].includes(mappedOffer.country.code.toLowerCase()) &&
    mappedOffer.firstPaymentAdvance
      ? getFollowingPaymentsOptions(
          {
            durationMax: mappedOffer.durationMax,
            durationMin: mappedOffer.durationMin,
          },
          {
            advancePayments: advancePayments?.value,
            paymentForm: radioOptions.paymentForm.value,
          },
          queryParamValues?.followingPayments ||
            currentState?.sliderOptions.followingPayments?.value,
        )
      : undefined;
  const term: SimulatorState['sliderOptions']['term'] =
    radioOptions.paymentForm.value &&
    getTermDependencies(
      mappedOffer.country.code,
      mappedOffer.firstPaymentAdvance,
      advancePayments?.value,
    )
      ? getTermOptions(
          {
            durationMin: mappedOffer.durationMin,
            durationMax: mappedOffer.durationMax,
          },
          {
            advancePayments: advancePayments?.value,
            followingPayments: followingPayments?.value,
            paymentForm: radioOptions.paymentForm.value,
          },
          queryParamValues?.term || currentState?.sliderOptions.term?.value,
        )
      : undefined;

  const interestRate: SimulatorState['sliderOptions']['interestRate'] =
    getInterestRateOptions(
      {
        interestRateMin: mappedOffer.interestRateMin || 0,
        interestRateMax: mappedOffer.interestRateMax || 0,
      },
      queryParamValues?.interestRateValue ||
        currentState?.sliderOptions?.interestRate?.value === undefined
        ? (mappedOffer.interestRateMax * 100 -
            mappedOffer.interestRateMin * 100) /
            2 +
            mappedOffer.interestRateMin * 100
        : currentState?.sliderOptions?.interestRate?.value,
    );

  const balloon: SimulatorState['sliderOptions']['balloon'] =
    (checkForBalloon(mappedOffer.balloonPeriod, term?.value) ||
      checkForInterestBasedBalloon(
        offer.interest_based_on_down_payment,
        selectOptions?.machine?.value,
      )) &&
    radioOptions.financeType.value === EFinanceTypes.PURCHASE
      ? getBalloonSliderOptions(
          {
            balloonPeriod: mappedOffer.balloonPeriod,
            country: {
              calculateRvWithNet: mappedOffer.country.calculateRvWithNet,
            },
            interestBasedOnDownPayment: {
              interestBasedBalloon:
                mappedOffer.interestBasedOnDownPayment?.interestBasedBalloon,
            },
            durationMax: mappedOffer.durationMax,
            durationMin: mappedOffer.durationMin,
          },
          {
            balloon: currentState?.sliderOptions.balloon?.value,
            machine: selectOptions?.machine?.value,
            machinePrice: machinePrice.value,
            downPayment: downPayment.value,
            term: term?.value,
          },
          queryParamValues?.balloon ||
            currentState?.sliderOptions.balloon?.value,
        )
      : undefined;
  const residualValue: SimulatorState['sliderOptions']['residualValue'] =
    // eslint-disable-next-line no-underscore-dangle
    checkForResidualValue(mappedOffer.residualValuePeriod, term?.value) &&
    radioOptions.financeType.value === EFinanceTypes.LEASING
      ? getResidualValueOptions(
          {
            residualValuePeriod: mappedOffer.residualValuePeriod,
            country: {
              calculateRvWithNet: mappedOffer.country.calculateRvWithNet,
            },
            durationMax: mappedOffer.durationMax,
            durationMin: mappedOffer.durationMin,
          },
          {
            machinePrice: machinePrice.value,
            residualValue: currentState?.sliderOptions.residualValue?.value,
            downPayment: downPayment.value,
            term: term?.value,
          },
          queryParamValues?.rv ||
            currentState?.sliderOptions.residualValue?.value,
        )
      : undefined;

  const sliderOptions: SimulatorState['sliderOptions'] = {
    advancePayments,
    balloon,
    downPayment,
    followingPayments,
    machinePrice,
    operatingHours,
    residualValue,
    term,
    interestRate,
  };

  const calc = new Calculator();
  return {
    sliderOptions,
    radioOptions,
    ...(selectOptions.machine
      ? { selectOptions }
      : { selectOptions: undefined }), // select options are currently only displayed if machine is select (which is optional)
    financeType: radioOptions.financeType.value,
    paymentForm: radioOptions.paymentForm.value,
    ...(selectOptions?.machine
      ? { machine: selectOptions.machine.value }
      : { machine: undefined }),
    machinePrice: sliderOptions.machinePrice.value,
    operatingHours: sliderOptions.operatingHours.value,
    downPayment: sliderOptions.downPayment.value,
    ...(sliderOptions.interestRate?.value
      ? {
          interestRate: sliderOptions.interestRate.value,
        }
      : { interestRate: undefined }),
    ...(sliderOptions.advancePayments?.value
      ? {
          advancePayments: sliderOptions.advancePayments.value,
        }
      : { advancePayments: undefined }),
    ...(sliderOptions.followingPayments?.value
      ? {
          followingPayments: sliderOptions.followingPayments.value,
        }
      : {
          followingPayments: undefined,
        }),
    ...(sliderOptions.term?.value
      ? { term: sliderOptions.term.value }
      : { term: undefined }),
    ...(sliderOptions.balloon?.value
      ? {
          balloon: sliderOptions.balloon.value,
        }
      : { balloon: undefined }),
    ...(sliderOptions.residualValue?.value
      ? {
          residualValue: sliderOptions.residualValue.value,
        }
      : { residualValue: undefined }),
    upselling: currentState?.upselling,
    offer,
    result: calc.calculate(
      offer,
      {
        advancePayments: sliderOptions.advancePayments?.value,
        balloon: sliderOptions.balloon?.value,
        downPayment: sliderOptions.downPayment.value,
        financeType: radioOptions.financeType.value,
        followingPayments: sliderOptions.followingPayments?.value,
        machinePrice: sliderOptions.machinePrice.value,
        operatingHours: sliderOptions.operatingHours.value,
        paymentForm: radioOptions.paymentForm.value,
        residualValue: sliderOptions.residualValue?.value,
        term: sliderOptions.term?.value,
        interestRate: sliderOptions.interestRate?.value,
        upselling: currentState?.upselling,
      },
      {
        advancePaymentAllowed:
          ['en_uk'].indexOf(offer.country.code.toLowerCase()) !== -1 &&
          mappedOffer.firstPaymentAdvance,
        isLeasing: radioOptions.financeType.value === EFinanceTypes.LEASING,
        downPaymentPercentage: radioOptions.downPayment?.value,
      },
    ),
  };
};
