import { Button, Input } from '@deere/fuel-react';
import { ReactComponent as LinkIcon } from '@deere/ux.brand-foundations/icons/link.svg';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'store';
import { UseContextStore } from 'zustand/context';

import mapOfferSettingsToURL from 'utils/mapOfferSettingsToURL';
import parseCountryCode from 'utils/parseCountryCode';
import trackData from 'utils/trackData';

const SaveCalculation = ({
  countryCode,
  title,
  useStore,
}: {
  countryCode: string;
  title: string;
  useStore: UseContextStore<Store>;
}) => {
  const { t } = useTranslation();
  const [saveUrl, setSaveUrl] = useState<string>('');
  const simulator = useStore();

  const trackClick = (trackCountryCode: string) => {
    const { locale, language, country } = parseCountryCode(trackCountryCode);

    trackData({
      options: {
        pageNameAddOfferName: true,
      },
      data: {
        event: 'Save Calculation',
        pageCategory: 'finalize',
        locale,
        language,
        country,
        breadCrumbs: [
          // not sure if we have to change this, can be removed?
          'servlet',
          'country=DE',
        ],
        offerName: title,
      },
    });
  };

  const generateSaveUrl = () => {
    try {
      // the local storage contains more props than the ISimulatorValues
      //  but only these values are needed in this case
      const valuesEncoded = btoa(
        JSON.stringify(mapOfferSettingsToURL(simulator)),
      );

      setSaveUrl(
        `${window.location.origin}${window.location.pathname}?v=${valuesEncoded}`,
      );
    } catch (e) {
      console.error('no valid simulation');
    }
  };

  return (
    <div className='sm:flex'>
      <a
        href='#saveCalculation'
        className='saveCalculation flex items-center'
        data-linkid='cta : finalize : Link to the simulation'
        data-linktype='internal-immediate'
      >
        <Button
          variant='text'
          startIcon={<LinkIcon className='fill-current' />}
          onClick={() => {
            trackClick(countryCode);
            generateSaveUrl();
          }}
        >
          {t('SIMULATOR.GET_LINK')}
        </Button>
      </a>
      {saveUrl && (
        <div className='flex'>
          <Input className='sm:ml-4' value={saveUrl} />
        </div>
      )}
    </div>
  );
};

export default SaveCalculation;
