import { Store } from 'store';
import { z } from 'zod';
import { UseContextStore } from 'zustand/context';

import EFinanceTypes from 'types/enums/EFinanceTypes';
import EFixedDownPaymentRates from 'types/enums/EFixedDownPaymentRates';
import EMachines from 'types/enums/EMachines';
import EPaymentForms from 'types/enums/EPaymentForms';
import { ZCalculatorResult } from 'types/interfaces/ICalculator';
import { ZApiOffer } from 'types/interfaces/IOffer';

import {
  EAdditionalOptions,
  ERadioOptions,
  ESelectOptions,
  ESliderOptions,
} from 'utils/offerCalculator/renderSimulation';

export interface IFinancingSimulationCard {
  topOrientation: boolean;
  onFinalizeClick: () => void;
  onConsultationClick?: () => void;
  printing?: boolean;
  useStore: UseContextStore<Store>;
  showInterestBox?: boolean;
  cbyoValue?: number;
}

export const ZRangeSliderProps = z.object({
  value: z.number(),
  min: z.number(),
  max: z.number(),
  step: z.number(),
  disabled: z.boolean().optional(),
  // @Todo naming is misleading here: this formats the value into a specific format that is used as label. Better naming would be formatLabel - also considering Todo below).
  formatValue: z.function().args(z.number()).returns(z.string()),
  onChange: z.function().args(z.number()).returns(z.void()),
  // @Todo no need for nested property here. Should be refactored to formatAdditionalLabel.
  additionalLabel: z
    .object({
      formatValue: z.function().args(z.any()).returns(z.string()),
    })
    .optional(),
});

export type RangeSliderProps = z.infer<typeof ZRangeSliderProps>;

export const ZSimulatorItemState = ZRangeSliderProps.omit({
  onChange: true,
  formatValue: true,
  additionalLabel: true,
}).extend({
  name: z.string(), // @Todo can be removed as key is same (check usage before!)
  label: z.string(),
});

export type SimulatorItemState = z.infer<typeof ZSimulatorItemState>;

const ZSimulatorStateBase = z.object({
  [ESliderOptions.ADVANCED_PAYMENTS]: z.number().optional(),
  [ESliderOptions.BALLOON]: z.number().optional(),
  [ESliderOptions.DOWN_PAYMENT]: z.number().optional(),
  [ERadioOptions.FINANCE_TYPE]: z.nativeEnum(EFinanceTypes),
  [ESliderOptions.FOLLOWING_PAYMENTS]: z.number().optional(),
  [ESliderOptions.MACHINE_PRICE]: z.number(),
  [ESelectOptions.MACHINE]: z.nativeEnum(EMachines).optional(),
  [ESliderOptions.OPERATING_HOURS]: z.number(),
  [ERadioOptions.PAYMENT_FORM]: z.nativeEnum(EPaymentForms),
  [ESliderOptions.RESIDUAL_VALUE]: z.number().optional(),
  [ESliderOptions.TERM]: z.number().positive().lte(10).optional(),
  [ESliderOptions.INTEREST_RATE]: z.number().optional(),
  [EAdditionalOptions.UPSELLING]: z.boolean().optional(),
});

export type SimulatorStateBase = z.infer<typeof ZSimulatorStateBase>;

export const ZSimulatorState = ZSimulatorStateBase.extend({
  offer: ZApiOffer,
  radioOptions: z.object({
    [ERadioOptions.FINANCE_TYPE]: z.object({
      value: z.nativeEnum(EFinanceTypes),
      defaultValue: z.nativeEnum(EFinanceTypes),
      items: z.array(
        z.object({
          label: z.string(),
          value: z.nativeEnum(EFinanceTypes),
        }),
      ),
      name: z.literal(ERadioOptions.FINANCE_TYPE),
    }),
    [ERadioOptions.PAYMENT_FORM]: z.object({
      value: z.nativeEnum(EPaymentForms),
      defaultValue: z.nativeEnum(EPaymentForms),
      items: z.array(
        z.object({
          label: z.string(),
          value: z.nativeEnum(EPaymentForms),
        }),
      ),
      name: z.literal(ERadioOptions.PAYMENT_FORM),
    }),
    // Special case: If interestBasedOnDownPayment is set, the downPayment slider is controlled by this radioGroup.
    [ESliderOptions.DOWN_PAYMENT]: z
      .object({
        value: z.nativeEnum(EFixedDownPaymentRates),
        defaultValue: z.nativeEnum(EFixedDownPaymentRates),
        items: z.array(
          z.object({
            label: z.string(),
            value: z.nativeEnum(EFixedDownPaymentRates),
          }),
        ),
        name: z.literal(ESliderOptions.DOWN_PAYMENT),
      })
      .optional(),
  }),
  result: ZCalculatorResult,
  selectOptions: z
    .object({
      [ESelectOptions.MACHINE]: z
        .object({
          value: z.nativeEnum(EMachines),
          defaultValue: z.nativeEnum(EMachines),
          items: z.array(
            z.object({
              label: z.string(),
              value: z.nativeEnum(EMachines),
            }),
          ),
          name: z.literal(ESelectOptions.MACHINE),
        })
        .optional(),
    })
    .optional(),
  sliderOptions: z.object({
    [ESliderOptions.ADVANCED_PAYMENTS]: ZSimulatorItemState.optional(),
    [ESliderOptions.BALLOON]: ZSimulatorItemState.optional(),
    [ESliderOptions.DOWN_PAYMENT]: ZSimulatorItemState,
    [ESliderOptions.FOLLOWING_PAYMENTS]: ZSimulatorItemState.optional(),
    [ESliderOptions.MACHINE_PRICE]: ZSimulatorItemState,
    [ESliderOptions.OPERATING_HOURS]: ZSimulatorItemState,
    [ESliderOptions.RESIDUAL_VALUE]: ZSimulatorItemState.optional(),
    [ESliderOptions.TERM]: ZSimulatorItemState.optional(),
    [ESliderOptions.INTEREST_RATE]: ZSimulatorItemState,
  }),
});

export type SimulatorState = z.infer<typeof ZSimulatorState>;
