import {
  SimulatorState,
  SimulatorStateBase,
} from 'types/interfaces/ISimulatorState';

export default (
  machinePrice: SimulatorStateBase['machinePrice'],
  downPayment: SimulatorStateBase['downPayment'],
): SimulatorState['result']['amount'] => {
  if (!downPayment) {
    return machinePrice > 0 ? machinePrice : 0;
  }
  return machinePrice - downPayment > 0 ? machinePrice - downPayment : 0;
};
