import EIndustry from 'types/enums/EIndustry';
import { IApiDealer, IDealer } from 'types/interfaces/IDealer';
import { IApiOffer, IOffer } from 'types/interfaces/IOffer';

import mapDealerResults from './mappings/mapDealerResults';
import mapOffer from './mappings/mapOffer';

import GeocoderResponse = google.maps.GeocoderResponse;

const API_URL = process.env.REACT_APP_API_URL;

const handleErrors = (response: Response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response.json();
};

const fetchData = <T>(path: string): Promise<T> => {
  if (!API_URL) {
    // @Todo add error handling (see fetchDealerResults function below)
    throw Error('No API url configured');
  }
  return fetch(`${API_URL + path}`)
    .then(res => res.json())
    .catch(e => {
      // @Todo add error handling (see fetchDealerResults function below)
      throw Error(e);
    });
};

export const fetchOffers = (countryCode: string): Promise<IOffer[]> =>
  fetchData<IApiOffer[]>(`countries/${countryCode}/offers`).then(offers =>
    offers.map(offer => mapOffer(offer)),
  );

export const fetchCBYOOffers = (
  locale: string,
  baseCode: string,
): Promise<IOffer[]> =>
  // We are sending "000000" to the backend when there is no baseCode
  // in order to receive the fallback offer
  // this string was decided with the backend team
  fetchData<IApiOffer[]>(
    `countries/${locale}/offers?baseCode=${baseCode || '00000'}`,
  ).then(offers => offers.map(offer => mapOffer(offer)));

export const fetchSingleOffer = (
  offerId: number,
): Promise<{ mappedOffer: IOffer; offer: IApiOffer }> =>
  fetchData<IApiOffer>(`offers/${offerId}`).then(res => ({
    mappedOffer: mapOffer(res),
    offer: res,
  }));

export const fetchDealerResults = (config: {
  country: string;
  lat: number;
  lng: number;
  radius: number;
  search: {
    industry: EIndustry;
    resultCount: number;
  };
}): Promise<IDealer[]> =>
  fetch(
    `https://tools.deere.com/dealerlocator/index.php?iso_country_code=${config.country}&lat=${config.lat}&lng=${config.lng}&radius=${config.radius}&search-pairs=${config.search.industry}-${config.search.resultCount}`,
  )
    .then(handleErrors)
    .then((dealers: { results: IApiDealer[] }) =>
      dealers.results.map(dealer =>
        // const zodParsed = ZApiDealer.safeParse(dealer);
        // if (!zodParsed.success) {
        //   // optional: validate data types of incoming dealers
        // }
        mapDealerResults(dealer),
      ),
    )
    .catch((e: Error) => {
      throw Error(e.message);
    });

export const fetchGeocodeByLatLng = (latLng: {
  lat: GeolocationPosition['coords']['latitude'];
  lng: GeolocationPosition['coords']['longitude'];
}): Promise<GeocoderResponse['results']> =>
  fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  )
    .then(handleErrors)
    .then((location: GeocoderResponse) => location.results)
    .catch((e: Error) => {
      throw Error(e.message);
    });
