import EIndustry from 'types/enums/EIndustry';

/**
 * Returns the translation key of the according industry id
 * @param industryId
 */
export default (industryId: EIndustry): string => {
  switch (industryId) {
    case 1:
      return 'INDUSTRIES.COMMERCIAL';
    case 4:
      return 'INDUSTRIES.FORESTRY';
    case 5:
      return 'INDUSTRIES.GOLF_AND_SPORTS';
    case 6:
      return 'INDUSTRIES.LAWN_AND_GARDEN';
    case 7:
      return 'INDUSTRIES.AGRICULTURE';
    case 97:
      return 'INDUSTRIES.LANDSCAPING_AND_GROUND_CARE';
    default:
      return 'INDUSTRIES.UNKNOWN';
  }
};
