import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Select,
  Option,
  Typography,
} from '@deere/fuel-react';
import { TextField } from '@mui/material';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useWizard } from 'react-use-wizard';

import { IFormtoolData } from 'types/IFormtoolData';

import './PersonalInformation.css';

type Inputs = {
  customer_salutation: IFormtoolData['customer_salutation'];
  firstName: IFormtoolData['firstName'];
  lastName: IFormtoolData['lastName'];
  email: IFormtoolData['email'];
  customer_phone: IFormtoolData['customer_phone'];
  customer_company: IFormtoolData['customer_company'];
  data_privacy_statement: boolean;
  marketing_consent: boolean;
};

interface IPersonalInformation {
  onNextStep: (
    stepData: Pick<
      IFormtoolData,
      | 'customer_salutation'
      | 'firstName'
      | 'lastName'
      | 'email'
      | 'customer_phone'
      | 'customer_company'
    >,
  ) => void;
}

const PersonalInformation = ({ onNextStep }: IPersonalInformation) => {
  const { t } = useTranslation();
  const { nextStep } = useWizard();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onFormSubmit: SubmitHandler<Inputs> = formData => {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    delete formData.data_privacy_statement; // not necessary for formtool
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    delete formData.marketing_consent; // not necessary for formtool
    onNextStep(formData);
    nextStep();
  };

  const createValidationMessage = (input: keyof Inputs) =>
    errors[input] ? (
      <FormHelperText error>
        {t(
          'CONSULTATION.STEPS.PERSONAL_INFORMATION.FORM.VALIDATION_REQUIRED_MESSAGE',
        )}
      </FormHelperText>
    ) : null;

  const getCheckboxLabel = () => (
    <>
      <Trans
        i18nKey='CONSULTATION.STEPS.PERSONAL_INFORMATION.FORM.DATA_PRIVACY_STATEMENT.LABEL'
        values={{
          url: t(
            'CONSULTATION.STEPS.PERSONAL_INFORMATION.FORM.DATA_PRIVACY_STATEMENT.URL',
          ),
        }}
        components={{
          // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
          a: <a />,
        }}
      />
      <span className='text-jd-red'>*</span>
    </>
  );

  const getCheckboxLabelMarketing = () => (
    <>
      <Trans
        i18nKey='CONSULTATION.STEPS.PERSONAL_INFORMATION.FORM.MARKETING_CONSENT.LABEL'
        values={{
          url: t(
            'CONSULTATION.STEPS.PERSONAL_INFORMATION.FORM.MARKETING_CONSENT.URL',
          ),
        }}
        components={{
          // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
          a: <a />,
        }}
      />
      <span className='text-jd-red'>*</span>
    </>
  );

  return (
    <div className='wizard-personal-information p-8'>
      <Typography size='2xl' component='p' fontWeight='bold' gutterBottom>
        {t('CONSULTATION.STEPS.PERSONAL_INFORMATION.STEP_TITLE')}
      </Typography>
      <div className='bg-jd-gray-bg p-8'>
        <form
          className='grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8'
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor='salutation'>
              {t('CONSULTATION.STEPS.PERSONAL_INFORMATION.FORM.GENDER')}
              <span className='text-jd-red'>*</span>
            </InputLabel>
            <Select
              id='salutation'
              defaultValue=''
              {...register('customer_salutation', { required: true })}
            >
              <Option value='mr'>
                {t('CONSULTATION.STEPS.PERSONAL_INFORMATION.FORM.MALE')}
              </Option>
              <Option value='ms'>
                {t('CONSULTATION.STEPS.PERSONAL_INFORMATION.FORM.FEMALE')}
              </Option>
            </Select>
          </FormControl>
          <FormControl fullWidth className='md:col-start-1'>
            <InputLabel htmlFor='first-name'>
              {t('CONSULTATION.STEPS.PERSONAL_INFORMATION.FORM.FIRST_NAME')}
              <span className='text-jd-red'>*</span>
            </InputLabel>
            <TextField
              id='first-name'
              {...register('firstName', { required: true })}
            />
            {createValidationMessage('firstName')}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor='last-name'>
              {t('CONSULTATION.STEPS.PERSONAL_INFORMATION.FORM.LAST_NAME')}
              <span className='text-jd-red'>*</span>
            </InputLabel>
            <TextField
              id='last-name'
              {...register('lastName', { required: true })}
            />
            {createValidationMessage('lastName')}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor='email'>
              {t('CONSULTATION.STEPS.PERSONAL_INFORMATION.FORM.EMAIL')}
              <span className='text-jd-red'>*</span>
            </InputLabel>
            <TextField id='email' {...register('email', { required: true })} />
            {createValidationMessage('email')}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor='phone'>
              {t('CONSULTATION.STEPS.PERSONAL_INFORMATION.FORM.PHONE')}
            </InputLabel>
            <TextField id='phone' {...register('customer_phone')} />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor='company'>
              {t('CONSULTATION.STEPS.PERSONAL_INFORMATION.FORM.COMPANY')}
            </InputLabel>
            <TextField id='company' {...register('customer_company')} />
          </FormControl>
          <FormControl component='fieldset' fullWidth className='md:col-span-2'>
            <FormControlLabel
              control={
                <Checkbox
                  {...register('data_privacy_statement', { required: true })}
                />
              }
              label={getCheckboxLabel()}
            />
            {createValidationMessage('data_privacy_statement')}
          </FormControl>

          <FormControl component='fieldset' fullWidth className='md:col-span-2'>
            <FormControlLabel
              control={
                <Checkbox
                  {...register('marketing_consent', { required: true })}
                />
              }
              label={getCheckboxLabelMarketing()}
            />
            {createValidationMessage('marketing_consent')}
          </FormControl>
          <div className='md:col-span-2'>
            <span className='text-jd-red'>*</span> ={' '}
            {t('CONSULTATION.STEPS.PERSONAL_INFORMATION.FORM.REQUIRED')}
          </div>

          <div className='md:col-span-2'>
            <Button variant='primary' type='submit'>
              {t('CONSULTATION.NEXT')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalInformation;
