import React from 'react';

interface IFinancingSimulationPrintingView {
  text: string;
  cardHeaderOrientation: string;
}

const FinancingSimulationPrintingView = ({
  text,
  cardHeaderOrientation,
}: IFinancingSimulationPrintingView) => (
  <div
    className={`p-6 border-b border-solid border-jd-black border-opacity-10 relative card-header ${cardHeaderOrientation} flex flex-wrap justify-between items-center bg-jd-yellow`}
  >
    <h2 className='font-bold text-lg pr-3'>{text}</h2>
  </div>
);

export default FinancingSimulationPrintingView;
