import { Button, Typography } from '@deere/fuel-react';
import { ReactComponent as GpsLocationIcon } from '@deere/ux.brand-foundations/icons/gps_location.svg';
import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useLocalStorage from 'hooks/useLocalStorage';

import {
  SimulatorState,
  ZSimulatorState,
} from 'types/interfaces/ISimulatorState';

interface IDealerSelectorSearch {
  autocompleteRef: React.RefObject<HTMLInputElement>;
  onLatLngChange: (latLng: google.maps.LatLngLiteral) => void;
}

const DealerSelectorSearch = ({
  autocompleteRef,
  onLatLngChange,
}: IDealerSelectorSearch) => {
  const { t } = useTranslation();

  const { offerId } = useParams<{ offerId: string }>();

  const [simulatorLS] = useLocalStorage<SimulatorState>(
    `simulation-${offerId}`,
    ZSimulatorState,
  );

  return (
    <>
      <Typography color='secondary' size='md' component='p' fontWeight='bold'>
        {t('DEALER_LOCATOR.SELECTOR.SEARCH.ENTER_ADDRESS')}
        <span style={{ color: 'red' }}>*</span>
      </Typography>
      {
        // Basically, this should be handled with the <Input /> element of the JD component library
        //  and its according `inputComponent` property, which receives the <Autocomplete /> component:
        //  https://github.com/ErrorPro/react-google-autocomplete/blob/2aea4a7d09275d607895c0a72f263990b6e097cc/docs/examples.js#L6
        //  This approach throws an error in the console (= warning):
        //  `Failed prop type: Invalid prop inputComponent supplied to forwardRef(InputBase) [...]`
        //  As a workaround, the <Input /> is omitted.
      }
      <div>
        <Autocomplete
          className='w-full mt-2 p-2 border-solid border-jd-black rounded-sm'
          style={{ borderWidth: '1px' }}
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          onPlaceSelected={place => {
            const lat = place.geometry?.location?.lat();
            const lng = place.geometry?.location?.lng();
            if (lat && lng) {
              onLatLngChange({ lat, lng });
            } else {
              // @Todo proper error handling
            }
          }}
          options={{
            fields: ['geometry.location'],
            types: ['geocode'],
            componentRestrictions: {
              country: simulatorLS.offer.country.code.slice(-2),
            },
          }}
          // When using the "Get current position" button,
          //  the value of the input must be set via a `ref`.
          ref={autocompleteRef}
        />
      </div>
      <div className='mt-2'>
        <Button
          className='py-1 px-2'
          onClick={() => {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                (position: GeolocationPosition) => {
                  onLatLngChange({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  });
                },
                // @Todo proper error handling
                () => null,
              );
            }
          }}
          variant='text'
        >
          <GpsLocationIcon className='mr-1 fill-current' />
          {t('DEALER_LOCATOR.SELECTOR.SEARCH.CURRENT_POSITION')}
        </Button>
      </div>
    </>
  );
};

export default DealerSelectorSearch;
