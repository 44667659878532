import { Typography } from '@deere/fuel-react';
import { ReactComponent as CheckIcon } from '@deere/ux.brand-foundations/icons/check.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWizard } from 'react-use-wizard';

import EWizardSteps from 'types/enums/EWizardSteps';
import IWizardSteps from 'types/interfaces/IWizardSteps';

/**
 * The values of the enum serve as css classes
 */
enum EStepState {
  'ACTIVE' = 'active',
  'VISITED' = 'visited',
  'INACTIVE' = 'inactive',
}

interface IWizardHeader {
  wizardSteps: IWizardSteps[];
}

const WizardHeader = ({ wizardSteps }: IWizardHeader) => {
  const { t } = useTranslation();
  const { activeStep } = useWizard();
  /**
   * Returns a "state" that is used as a class for styling purposes only.
   * @param index
   * @param key
   */
  const getStepState = (
    index: number,
    key: IWizardSteps['key'],
  ): EStepState => {
    switch (true) {
      // Special case: "confirmation" screen can not be active.
      //  Once the user arrives at the step, it is marked as visited
      case key === EWizardSteps.CONFIRMATION && index <= activeStep:
        return EStepState.VISITED;
      case index === activeStep:
        return EStepState.ACTIVE;
      case index < activeStep:
        return EStepState.VISITED;
      default:
        return EStepState.INACTIVE;
    }
  };
  return (
    <div className='wizard-head bg-jd-gray-bg p-8'>
      <div className='flex md:w-2/3'>
        {wizardSteps.map((step, index) => (
          <div
            key={step.key}
            className={`wizard-head-item ${getStepState(index, step.key)}`}
          >
            <div className='flex'>
              <div className='circle flex rounded-full h-8 w-8 text-white text-center'>
                <span className='align-self-center flex-grow'>
                  {getStepState(index, step.key) === EStepState.VISITED ? (
                    <CheckIcon className='fill-current m-auto' />
                  ) : (
                    index + 1
                  )}
                </span>
              </div>
              {wizardSteps.length - 1 > index && (
                <div className='mt-3 mx-2 h-0.5 bg-darkgray flex-grow' />
              )}
            </div>
            <Typography
              size='md'
              fontWeight='bold'
              component='p'
              className='wizard-head-item-description mt-2'
            >
              {t(step.headerTitle)}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WizardHeader;
