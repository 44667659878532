export default (countryCode: string | undefined) => {
  let currentCountryCode = countryCode;
  if (typeof currentCountryCode === 'undefined' || currentCountryCode === '') {
    return {
      locale: '',
      language: '',
      country: '',
    };
  }

  if (currentCountryCode === 'en_UK') {
    currentCountryCode = 'en_GB';
  }

  const countryCodeNormalized = currentCountryCode.replace('_', '-');
  const countryCodeNormalizedParts = countryCodeNormalized.split('-');
  const language = countryCodeNormalizedParts[0] ?? '';
  const country = countryCodeNormalizedParts[1] ?? '';
  return {
    locale: countryCodeNormalized.toLowerCase(),
    language: language.toLowerCase(),
    country: country.toLowerCase(),
  };
};
