import { CircularProgress } from '@deere/fuel-react';
import React from 'react';

const Loader = () => (
  <section className='flex flex-1 justify-center items-center'>
    <CircularProgress size='lg' />
  </section>
);

export default Loader;
