import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'store';
import { UseContextStore } from 'zustand/context';
import shallow from 'zustand/shallow';

import RangeSlider from 'components/RangeSlider/RangeSlider';
import Triangle from 'components/Triangle';

import usePrevious from 'hooks/usePrevious';

import { SimulatorItemState } from 'types/interfaces/ISimulatorState';

import getFormatValuesForRangeSlider from 'utils/getFormatValuesForRangeSlider';
import getMachinePriceMaxForCbyo from 'utils/getMachinePriceMaxForCbyo';
import getMachinePriceMinForCbyo from 'utils/getMachinePriceMinForCbyo';
import { ESliderOptions } from 'utils/offerCalculator/renderSimulation';

export interface SimulatorItemProps {
  sliderItemOptions: SimulatorItemState | undefined;
  disabled?: boolean;
  useStore: UseContextStore<Store>;
  cbyoValue?: number;
}

const SimulatorItem = ({
  sliderItemOptions,
  disabled,
  useStore,
  cbyoValue,
}: SimulatorItemProps) => {
  const { t } = useTranslation();
  const { updateSimulator, sliderOptions, offer, machinePrice, resultAmount } =
    useStore(
      state => ({
        sliderOptions: state.sliderOptions,
        updateSimulator: state.updateSimulator,
        offer: state.offer, // needed to get the format value functions
        machinePrice: state.machinePrice, // needed to get the format value functions
        resultAmount: state.result.amount, // needed to get the format value functions
      }),
      shallow,
    );
  const [cbyoLeftPosition, setCbyoLeftPosition] = useState<string>('');

  if (!sliderItemOptions) {
    return <div />;
  }
  const [textValue, setTextValue] = useState<string>(
    sliderItemOptions.value.toString(),
  );
  const [itemOptions, setItemOptions] = useState<{
    name: ESliderOptions;
    value: number;
  }>({
    name: sliderItemOptions.name as ESliderOptions,
    value: sliderItemOptions.value,
  });
  const prevItemOptionValue = usePrevious(itemOptions.value);

  const {
    value,
    label,
    min,
    max,
    step,
    disabled: sliderDisabled,
  } = sliderItemOptions;
  const { formatValue, additionalLabel } = getFormatValuesForRangeSlider(
    sliderItemOptions,
    offer,
    machinePrice,
    resultAmount,
  );

  const getAllowedValueHelper = (
    providedValue: number,
    minValue: number,
    maxValue: number,
  ): number => {
    let currentValue = providedValue;
    if (Number.isNaN(currentValue)) {
      currentValue = minValue;
    }
    if (typeof minValue === 'number' && currentValue < minValue) {
      currentValue = minValue;
    }
    if (typeof maxValue === 'number' && currentValue > maxValue) {
      currentValue = maxValue;
    }
    return currentValue;
  };

  const getAllowedValue = (providedValue: number) => {
    let currentValue = providedValue;
    if (itemOptions.name === ESliderOptions.MACHINE_PRICE && cbyoValue) {
      currentValue = getAllowedValueHelper(
        providedValue,
        getMachinePriceMinForCbyo(cbyoValue, min),
        getMachinePriceMaxForCbyo(cbyoValue, max),
      );
    } else {
      getAllowedValueHelper(providedValue, min, max);
    }

    return currentValue;
  };

  /*
   * We are calculating the left position for the cbyo value:
   *    1. we identify the percentage correction because we work with a min and a max value,
   *  min cannot be 0 so that is why we need to substract this value later from each percentage that is calculated
   *    2. we calculate the percentage and apply the percentage correction
   *    3. we get the whole component width and we substract half of it so that it is perfectly centered
   */

  useEffect(() => {
    if (cbyoValue) {
      const cbyoMaxSliderValue =
        getMachinePriceMaxForCbyo(cbyoValue || 0, max) - min;
      const cbyoPercentCorrection = (100 * min) / cbyoMaxSliderValue;
      const cbyoPercent =
        ((cbyoValue || 0) * 100) / cbyoMaxSliderValue - cbyoPercentCorrection;
      const cbyoButton = document.getElementById('cbyoButton');
      const cbyoButtonWidth = cbyoButton?.offsetWidth || 0;
      const cbyoButtonWidthHalf = cbyoButtonWidth / 2;
      setCbyoLeftPosition(`calc(${cbyoPercent}% - ${cbyoButtonWidthHalf}px)`);
    }
  }, [cbyoValue]);

  useEffect(() => {
    const maxValue = value > max ? max : value;
    const minValue = maxValue < min ? min : maxValue;

    setItemOptions({
      ...itemOptions,
      value: minValue,
    });
  }, [value, max, min]);

  useEffect(() => {
    // @Todo this should be part of renderSimulation (check if this can be just removed)
    const exceptionValues =
      itemOptions.name === 'term'
        ? {
            residualValue: undefined,
            balloon: undefined,
          }
        : {};

    // @Todo this seems to be hacky - we are checking for Date if we update the state: Was this implemented to avoid unnecessary state updates? Check!
    if (prevItemOptionValue !== itemOptions.value) {
      updateSimulator({
        [itemOptions.name]: itemOptions.value,
        sliderOptions: {
          ...sliderOptions,
          [itemOptions.name]: {
            ...sliderOptions[itemOptions.name],
            value: itemOptions.value,
          },
        },
        ...exceptionValues,
      });
    }
  }, [itemOptions.value]);

  return (
    <div className='px-6 flex flex-wrap justify-between items-center print-layout-container'>
      <h3 className='pb-3 font-bold uppercase opacity-40 print-layout'>
        {t(label)}
      </h3>
      {!disabled && !sliderDisabled && (
        <div className='pb-3 text-xs hidden-print'>
          <TextField
            type='text'
            size='small'
            className={cbyoValue ? 'cbyo-input' : ''}
            onClick={e => {
              /**
               * both calls may be needed to prevent that number inputs do not
               * correctly focus the value - the selection is done to make it
               * possible for users (who don't understand that they can delete
               * it after entering another number) to easily replace the whole
               * value
               */
              // @ts-ignore
              e.target.focus();
              // @ts-ignore
              e.target.select();
            }}
            value={textValue}
            onChange={event => {
              const changedValue = event.target.value;
              setTextValue(changedValue);
              setItemOptions({
                name: itemOptions.name,
                value: getAllowedValue(Number(changedValue)),
              });
            }}
            onBlur={() => {
              setTextValue(getAllowedValue(Number(textValue)).toString());
            }}
          />
        </div>
      )}
      <span className='only-on-print'>
        {formatValue(getAllowedValue(Number(itemOptions.value)))}
      </span>
      <div className='w-full hidden-print'>
        {cbyoValue ? (
          <>
            <RangeSlider
              value={getAllowedValue(Number(itemOptions.value))}
              formatValue={formatValue}
              onChange={newValue => {
                setItemOptions({
                  name: itemOptions.name,
                  value: newValue,
                });
                setTextValue(newValue.toString());
              }}
              min={getMachinePriceMinForCbyo(cbyoValue, min)}
              max={getMachinePriceMaxForCbyo(cbyoValue, max)}
              step={step}
              additionalLabel={additionalLabel}
              disabled={disabled || sliderDisabled}
            />
            {/* cbyoPercent is corrected to have the same value as the slider and the translateX is made based on the
            length of the cbyoValue string to be more accurate */}
            <div className='w-full py-3'>
              <button
                id='cbyoButton'
                type='button'
                className='relative flex flex-column'
                style={{
                  left: cbyoLeftPosition,
                  top: '-30px',
                }}
                onClick={() => {
                  setItemOptions({
                    name: itemOptions.name,
                    value: cbyoValue,
                  });
                  setTextValue(cbyoValue.toString());
                }}
              >
                <div className='mx-auto'>
                  <Triangle direction='top' w={30} h={15} />
                </div>
                <p className='text-sm hover:text-jd-green'>
                  {formatValue(cbyoValue)}*
                </p>
              </button>
            </div>
          </>
        ) : (
          <RangeSlider
            value={getAllowedValue(Number(itemOptions.value))}
            formatValue={formatValue}
            onChange={newValue => {
              setItemOptions({
                name: itemOptions.name,
                value: newValue,
              });
              setTextValue(newValue.toString());
            }}
            min={min}
            max={max}
            step={step}
            additionalLabel={additionalLabel}
            disabled={disabled || sliderDisabled}
          />
        )}
      </div>
    </div>
  );
};

SimulatorItem.propTypes = {
  disabled: PropTypes.bool,
  additionalLabel: PropTypes.shape({
    formatValue: PropTypes.func,
  }),
  cbyoValue: PropTypes.number,
};
SimulatorItem.defaultProps = {
  disabled: false,
  additionalLabel: null,
  cbyoValue: null,
};

export default SimulatorItem;
