import { Alert, Button, CircularProgress, Typography } from '@deere/fuel-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWizard } from 'react-use-wizard';

import ERequestState from 'types/enums/ERequestState';

interface IConfirmation {
  currentState: ERequestState;
}

const Confirmation = ({ currentState }: IConfirmation) => {
  const { t } = useTranslation();
  const { previousStep } = useWizard();

  return (
    <div className='wizard-confirmation p-8'>
      {currentState === ERequestState.LOADING && (
        <CircularProgress size='lg' variant='indeterminate' />
      )}
      {currentState === ERequestState.ERROR && (
        <>
          <Alert
            variant='error'
            title={t('CONSULTATION.STEPS.CONFIRMATION.ERROR')}
          />
          {/* Go step back */}
          <div className='mt-8'>
            <Button variant='primary' onClick={previousStep}>
              {t('CONSULTATION.BACK')}
            </Button>
          </div>
        </>
      )}
      {currentState === ERequestState.SUCCESS && (
        <>
          <Typography size='2xl' component='p' fontWeight='bold' gutterBottom>
            {t('CONSULTATION.STEPS.CONFIRMATION.STEP_TITLE')}
          </Typography>
          <Alert
            variant='warning'
            title={t('CONSULTATION.STEPS.CONFIRMATION.ALERT')}
          />
          <div className='bg-jd-gray-bg p-8'>
            <Typography size='md' fontWeight='bold' component='p'>
              {t('CONSULTATION.STEPS.CONFIRMATION.MESSAGE.THANK_YOU')}
            </Typography>
            <Typography size='md' component='p' className='mt-2'>
              {t('CONSULTATION.STEPS.CONFIRMATION.MESSAGE.CONFIRM')}
            </Typography>
            <Typography size='md' component='p' className='mt-2'>
              {t('CONSULTATION.STEPS.CONFIRMATION.MESSAGE.YOUR_JD_TEAM')}
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};

export default Confirmation;
