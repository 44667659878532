import { IApiDealer, IDealer } from 'types/interfaces/IDealer';

export default (dealerResult: IApiDealer): IDealer => ({
  accountKey: dealerResult.accountKey,
  additionalInfo: {
    associatedNetwork: dealerResult.additionalInfo.associatedNetwork || '',
  },
  contactDetail: {
    email: dealerResult.contactDetail.email,
    fax: dealerResult.contactDetail.fax || '',
    phone: dealerResult.contactDetail.phone,
    website: dealerResult.contactDetail.website || '',
  },
  distance: dealerResult.distance,
  formattedAddress: dealerResult.formattedAddress,
  latitude: dealerResult.latitude,
  locationId: dealerResult.locationId,
  locationName: dealerResult.locationName,
  longitude: dealerResult.longitude,
  partIndicator: dealerResult.partIndicator,
  sellIndicator: dealerResult.sellIndicator,
  serviceIndicator: dealerResult.serviceIndicator,
  uom: dealerResult.uom,
});
