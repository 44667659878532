enum EIndustry {
  COMMERCIAL = 1,
  FORESTRY = 4,
  GOLF_AND_SPORTS = 5,
  LAWN_AND_GARDEN = 6,
  AGRICULTURE = 7,
  LANDSCAPING_AND_GROUND_CARE = 97,
}

export default EIndustry;
