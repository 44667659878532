import { Circle, Map, Marker } from 'google-maps-react';
import React, { useRef, useState } from 'react';

import { IDealer } from 'types/interfaces/IDealer';

interface IDealerMap {
  searchRadius: number;
  dealers: IDealer[];
  latLng: google.maps.LatLngLiteral;
}

const DealerMap = ({ searchRadius, dealers, latLng }: IDealerMap) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const prevMarkersRef = useRef<google.maps.Marker[]>([]);
  const mapDealerToMarker = (
    dealer: IDealer,
    position: number,
  ): google.maps.Marker =>
    new google.maps.Marker({
      position: {
        lat: dealer.latitude,
        lng: dealer.longitude,
      },

      label: {
        text: position.toString(),
        className: 'h-6 text-white', // todo check if this is applied on purged tailwind!
      },
      icon: {
        url: '/assets/location-full-green.svg',
        scaledSize: new google.maps.Size(48, 48),
      },
      map,
    });

  const clearMarkers = () => {
    prevMarkersRef.current.forEach(marker => marker.setMap(null));
    prevMarkersRef.current = [];
  };

  const createMarkers = () => {
    if (!dealers.length) {
      clearMarkers();
      return null;
    }

    return dealers.map((dealer, i) => {
      const marker = mapDealerToMarker(dealer, i + 1);
      prevMarkersRef.current.push(marker);
      return (
        // cursed react keys: Markers do not have a unique identifier
        <Marker key={Math.random().toString()} {...marker} />
      );
    });
  };

  return (
    <div className='relative h-96'>
      {/* @ts-ignore */}
      <Map
        google={google}
        zoom={7}
        center={latLng}
        onReady={(mapProps, mapInit) => mapInit && setMap(mapInit)}
        centerAroundCurrentLocation
      >
        <Circle
          radius={searchRadius * 1000}
          center={latLng}
          strokeWeight={1}
          strokeColor='#9E9E9E' // see tailwind config darkgray
          fillColor='#FDDA01' // see tailwind config jd-yellow
          fillOpacity={0.2}
        />
        {map && createMarkers()}
      </Map>
    </div>
  );
};

export default DealerMap;
