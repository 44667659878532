import { Footer, FooterLink, FooterNav } from '@deere/fuel-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './Footer.css';

type UtilityLinksModel = {
  LABEL: string;
  LINK: string;
};

const AppFooter = () => {
  const { t } = useTranslation();
  const [utilityLinks, setUtilityLinks] = useState<{
    [key: string]: UtilityLinksModel;
  }>(() => t('FOOTER.UTILITY_LINKS', { returnObjects: true }));

  useEffect(
    () => setUtilityLinks(t('FOOTER.UTILITY_LINKS', { returnObjects: true })),
    [t],
  );

  return (
    <section className='footer-section'>
      <Footer>
        <FooterNav aria-label='footer navigation'>
          <div className='utility-links m-auto'>
            <p>
              {Object.values(utilityLinks).map(ITEM => (
                <FooterLink
                  style={{
                    display: 'inline-block',
                    width: 'auto',
                    whiteSpace: 'normal',
                  }}
                  key={ITEM.LABEL + ITEM.LINK}
                  text={ITEM.LABEL}
                  href={ITEM.LINK}
                />
              ))}
            </p>
            <p className='text-center'>
              {/*
                this is automatically translated by OneTrust depending on the set language
                which also changes the content and texts of the cookie consent banner
                basically they do the whole localization part which also affects cookie handling
              */}
              <FooterLink
                className='ot-sdk-show-settings'
                id='otFooter'
                style={{ cursor: 'pointer' }}
                text='Cookies Preferences'
              />
            </p>
          </div>
        </FooterNav>
      </Footer>
    </section>
  );
};

export default AppFooter;
