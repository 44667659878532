import { z } from 'zod';

import EFinanceTypes from 'types/enums/EFinanceTypes';
import EFixedDownPaymentRates from 'types/enums/EFixedDownPaymentRates';
import EMachines from 'types/enums/EMachines';
import EPaymentForms from 'types/enums/EPaymentForms';

const ZApiBalloonPeriodItem = z.intersection(
  z.object({
    1: z.number(),
    2: z.number(),
    3: z.number(),
  }),
  z.record(z.number()),
);

const ZApiBalloonPeriod = z.intersection(
  z.object({
    1: ZApiBalloonPeriodItem,
    2: ZApiBalloonPeriodItem,
    3: ZApiBalloonPeriodItem,
    4: ZApiBalloonPeriodItem,
    5: ZApiBalloonPeriodItem,
    6: ZApiBalloonPeriodItem,
    7: ZApiBalloonPeriodItem,
    8: ZApiBalloonPeriodItem,
    9: ZApiBalloonPeriodItem,
    10: ZApiBalloonPeriodItem,
  }),
  z.record(ZApiBalloonPeriodItem),
);

export type IApiBalloonPeriod = z.infer<typeof ZApiBalloonPeriod>;

const ZFrequencyValues = z.object({
  [EPaymentForms.MONTHLY]: z.number().gte(0).lte(100),
  [EPaymentForms.QUARTERLY]: z.number().gte(0).lte(100),
  [EPaymentForms.SEMIANNUALLY]: z.number().gte(0).lte(100),
  [EPaymentForms.ANNUALLY]: z.number().gte(0).lte(100),
});

const ZApiInterestBasedOnDownPaymentRate = z.object({
  [EFixedDownPaymentRates.PERCENT_10]: ZFrequencyValues,
  [EFixedDownPaymentRates.PERCENT_35]: ZFrequencyValues,
  [EFixedDownPaymentRates.PERCENT_50]: ZFrequencyValues,
});

const ZApiInterestBasedOnDownPaymentRates = z.intersection(
  z.object({
    1: ZApiInterestBasedOnDownPaymentRate,
    2: ZApiInterestBasedOnDownPaymentRate,
    3: ZApiInterestBasedOnDownPaymentRate,
    4: ZApiInterestBasedOnDownPaymentRate,
    5: ZApiInterestBasedOnDownPaymentRate,
    6: ZApiInterestBasedOnDownPaymentRate,
    7: ZApiInterestBasedOnDownPaymentRate,
    8: ZApiInterestBasedOnDownPaymentRate,
    9: ZApiInterestBasedOnDownPaymentRate,
    10: ZApiInterestBasedOnDownPaymentRate,
  }),
  z.record(ZApiInterestBasedOnDownPaymentRate),
);

type IApiInterestBasedOnDownPaymentRates = z.infer<
  typeof ZApiInterestBasedOnDownPaymentRates
>;

const ZApiInterestBasedOnDownPaymentBalloonMachineNames = z.object({
  [EMachines.MACHINE_1]: z.string(),
  [EMachines.MACHINE_2]: z.string(),
  [EMachines.MACHINE_3]: z.string(),
  [EMachines.MACHINE_4]: z.string(),
  [EMachines.MACHINE_5]: z.string(),
  [EMachines.MACHINE_6]: z.string(),
  [EMachines.MACHINE_7]: z.string(),
  [EMachines.MACHINE_8]: z.string(),
  [EMachines.MACHINE_9]: z.string(),
  [EMachines.MACHINE_10]: z.string(),
});

type IApiInterestBasedOnDownPaymentBalloonMachineNames = z.infer<
  typeof ZApiInterestBasedOnDownPaymentBalloonMachineNames
>;

const ZApiInterestBasedOnDownPaymentBalloonMachineNumbers = z.object({
  [EMachines.MACHINE_1]: z.number(),
  [EMachines.MACHINE_2]: z.number(),
  [EMachines.MACHINE_3]: z.number(),
  [EMachines.MACHINE_4]: z.number(),
  [EMachines.MACHINE_5]: z.number(),
  [EMachines.MACHINE_6]: z.number(),
  [EMachines.MACHINE_7]: z.number(),
  [EMachines.MACHINE_8]: z.number(),
  [EMachines.MACHINE_9]: z.number(),
  [EMachines.MACHINE_10]: z.number(),
});

const ZApiInterestBasedOnDownPaymentBalloonValues = z.intersection(
  z.object({
    1: ZApiInterestBasedOnDownPaymentBalloonMachineNumbers,
    2: ZApiInterestBasedOnDownPaymentBalloonMachineNumbers,
    3: ZApiInterestBasedOnDownPaymentBalloonMachineNumbers,
    4: ZApiInterestBasedOnDownPaymentBalloonMachineNumbers,
    5: ZApiInterestBasedOnDownPaymentBalloonMachineNumbers,
    6: ZApiInterestBasedOnDownPaymentBalloonMachineNumbers,
    7: ZApiInterestBasedOnDownPaymentBalloonMachineNumbers,
    8: ZApiInterestBasedOnDownPaymentBalloonMachineNumbers,
    9: ZApiInterestBasedOnDownPaymentBalloonMachineNumbers,
    10: ZApiInterestBasedOnDownPaymentBalloonMachineNumbers,
  }),
  z.record(ZApiInterestBasedOnDownPaymentBalloonMachineNumbers),
);

type IApiInterestBasedOnDownPaymentBalloonValues = z.infer<
  typeof ZApiInterestBasedOnDownPaymentBalloonValues
>;

const ZApiRates = z.intersection(
  z.object({
    1: z.number(),
    2: z.number(),
    3: z.number(),
    4: z.number(),
    5: z.number(),
    6: z.number(),
    7: z.number(),
    8: z.number(),
    9: z.number(),
    10: z.number(),
  }),
  z.record(z.number()),
);

export type IApiRates = z.infer<typeof ZApiRates>;

export const ZApiOffer = z.object({
  _r_v_period: ZApiBalloonPeriod,
  allow_user_preference_for_interest_rate: z.boolean().optional(),
  balloon_amount_default: z.number(),
  balloon_amount_max: z.number(),
  balloon_amount_min: z.number(),
  balloon_period: ZApiBalloonPeriod,
  country: z.object({
    calculate_rv_with_net: z.boolean(),
    code: z.string(),
    currency: z.string(),
    disclaimer: z.string().optional(),
    first_payment_advance: z.boolean().optional(),
    id: z.number(),
    name: z.string(),
    newsletter_subscribe: z.boolean(),
    newsletter_subscribe_link: z.string().optional(),
    show_a_p_r: z.boolean(),
  }),
  description: z.string(),
  disclaimer: z.string().optional(),
  down_payment_max: z.number(),
  down_payment_min: z.number(),
  duration_max: z.number(),
  duration_min: z.number(),
  featured: z.boolean(),
  finance_types: z.array(z.nativeEnum(EFinanceTypes)),
  first_payment_advance: z.boolean(),
  interest_based_on_down_payment: z
    .object({
      rates: ZApiInterestBasedOnDownPaymentRates,
      interest_based_balloon: z
        .object({
          balloon_values_per_year: ZApiInterestBasedOnDownPaymentBalloonValues,
          machines: ZApiInterestBasedOnDownPaymentBalloonMachineNames,
        })
        .optional(),
      show_interest_rate: z.boolean().optional(),
    })
    .optional(),
  interest_rate_min: z.number().optional(),
  interest_rate_max: z.number().optional(),
  id: z.number(),
  image: z.string(),
  image_resized: z.string(),
  lease_rates: ZApiRates,
  loan_opening_fee: z.number(),
  loan_opening_fee_percent: z.number(),
  loan_opening_fee_type: z.string(),
  loan_rates: ZApiRates,
  machine_price_max: z.number(),
  machine_price_min: z.number(),
  operating_hours_label: z.string(),
  operating_hours_max: z.number(),
  operating_hours_min: z.number(),
  payment_forms: z.array(z.nativeEnum(EPaymentForms)),
  priority: z.number(),
  // publication_end and publication_start are not defined on CBYO Fallback offer
  publication_end: z.string().optional(),
  publication_start: z.string().optional(),
  residual_value_default: z.number(),
  residual_value_max: z.number(),
  residual_value_min: z.number(),
  show_interest_rate: z.boolean(),
  show_operating_hours: z.boolean().optional(),
  status: z.enum(['draft', 'published', 'archived']),
  subtitle: z.string(),
  title: z.string(),
  upselling_description: z.string().optional(),
  upselling_description_title: z.string().optional(),
  upselling_enabled: z.boolean(),
  upselling_product_name: z.string().optional(),
  upselling_rates: ZApiRates.optional(),
  upselling_title: z.string().optional(),
  visible: z.boolean(),
});

export type IApiOffer = z.infer<typeof ZApiOffer>;

export interface IOffer {
  balloonAmountDefault: number;
  balloonAmountMax: number;
  balloonAmountMin: number;
  balloonPeriod: IApiBalloonPeriod;
  country: {
    calculateRvWithNet: boolean;
    code: string;
    currency: string;
    disclaimer?: string;
    id: number;
    name: string;
    newsletterSubscribe: boolean;
    newsletterSubscribeLink?: string;
    showAPR: boolean;
  };
  description: string;
  disclaimer?: string;
  downPaymentMax: number;
  downPaymentMin: number;
  durationMax: number;
  durationMin: number;
  featured: boolean;
  financeTypes: EFinanceTypes[];
  firstPaymentAdvance: boolean;
  id: number;
  image: string;
  imageResized: string;
  showInterestRate: boolean;
  allowUserPreferenceForInterestRate?: boolean;
  interestRateMin: number;
  interestRateMax: number;
  interestBasedOnDownPayment?: {
    rates: IApiInterestBasedOnDownPaymentRates;
    interestBasedBalloon?: {
      balloonValuesPerYear: IApiInterestBasedOnDownPaymentBalloonValues;
      machines: IApiInterestBasedOnDownPaymentBalloonMachineNames;
    };
  };
  leaseRates: IApiRates;
  loanOpeningFee: number;
  loanOpeningFeePercent: number;
  loanOpeningFeeType: string;
  loanRates: IApiRates;
  machinePriceMax: number;
  machinePriceMin: number;
  operatingHoursLabel: string;
  operatingHoursMax: number;
  operatingHoursMin: number;
  showOperatingHours: boolean;
  paymentForms: EPaymentForms[];
  priority: number;
  // publicationEnd and publicationStart are not defined on CBYO Fallback offer
  publicationEnd?: Date;
  publicationStart?: Date;
  residualValueDefault: number;
  residualValueMax: number;
  residualValueMin: number;
  residualValuePeriod: IApiBalloonPeriod;
  status: 'draft' | 'published' | 'archived';
  subtitle: string;
  title: string;
  upsellingDescriptionTitle?: string;
  upsellingDescription?: string;
  upsellingEnabled: boolean;
  upsellingProductName?: string;
  upsellingRates?: IApiRates;
  upsellingTitle?: string;
  visible: boolean;
}
