import { ReactComponent as LocationIcon } from '@deere/ux.brand-foundations/icons/location.svg';
import { Header } from '@deere/ux.uxframe-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './Header.css';

const AppHeaderLinks = () => {
    const { t } = useTranslation();

    return (
        <ul className='h-full flex justify-center items-center header-links hidden-print'>
            <li className='mx-3 font-bold'>
                <a
                    href={t('HEADER.LOCATE_DEALER_LINK')}
                    className='flex justify-center items-center hover:text-jd-green hover:no-underline'
                    target='_blank'
                    rel='noreferrer noopener'
                >
                    <LocationIcon /> {t('HEADER.LOCATE_DEALER')}
                </a>
            </li>
            <li className='mx-3 font-bold'>
                <a
                    href={t('HEADER.MY_JOHN_DEERE_LINK')}
                    className='flex justify-center items-center hover:text-jd-green hover:no-underline'
                    target='_blank'
                    rel='noreferrer noopener'
                >
                    {t('HEADER.MY_JOHN_DEERE')}
                </a>
            </li>
        </ul>
    );
};

const getOffersURL = () => {
    const currentURL = window.location.href;
    const regex = new RegExp('(?<=offers/).{0,5}');
    const regexMatch = regex.exec(currentURL)
    const returnResult = `/offers/${regexMatch}`;
    console.log('The formed URL ', returnResult);
    return returnResult
};

const AppHeader = () => (
    <section className='header-section'>
        <Header
            search={false}
            logoLink={getOffersURL()}
            skipLink={getOffersURL()}
            pageHeadingLink={getOffersURL()}
            pageHeading=''
            navbar
            navbarCustom
            mobileMenu
            headerTitleComponent={<AppHeaderLinks />}
        />
    </section>
);

export default AppHeader;