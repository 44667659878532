import React, { useState } from 'react';

import { IDealer } from 'types/interfaces/IDealer';

import DealerMap from './DealerMap';
import DealerSelector from './DealerSelector/DealerSelector';

const DEALER_SEARCH_RADIUS = 1000;

interface IDealerLocator {
  onDealerSelected: (dealer: IDealer) => void;
}

const DealerLocator = ({ onDealerSelected }: IDealerLocator) => {
  const [latLng, setLatLng] = useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });
  const [dealers, setDealers] = useState<IDealer[]>([]);

  return (
    <section className='flex flex-wrap bg-jd-gray-bg-lighter'>
      <div className='w-full md:w-1/2'>
        <DealerMap
          dealers={dealers}
          latLng={latLng}
          searchRadius={DEALER_SEARCH_RADIUS}
        />
      </div>
      <div className='w-full md:w-1/2'>
        <DealerSelector
          searchRadius={DEALER_SEARCH_RADIUS}
          onLatLngChange={setLatLng}
          onDealersChange={setDealers}
          onDealerSelected={onDealerSelected}
        />
      </div>
    </section>
  );
};

export default DealerLocator;
