import { IApiOffer } from 'types/interfaces/IOffer';
import {
  RangeSliderProps,
  SimulatorItemState,
  SimulatorState,
  SimulatorStateBase,
} from 'types/interfaces/ISimulatorState';

import formatNumber from './formatNumber';
import { ESliderOptions } from './offerCalculator/renderSimulation';

export default (
  slider: SimulatorItemState,
  offer: IApiOffer,
  machinePrice: SimulatorStateBase['machinePrice'],
  resultAmount: SimulatorState['result']['amount'],
): Pick<RangeSliderProps, 'formatValue' | 'additionalLabel'> => {
  let formatValue: RangeSliderProps['formatValue'];
  let additionalLabel: RangeSliderProps['additionalLabel'];
  switch (slider.name) {
    case ESliderOptions.BALLOON:
    case ESliderOptions.RESIDUAL_VALUE:
      formatValue = (valueToFormat: number) =>
        `${Math.round(
          valueToFormat /
            (Number(
              offer?.country.calculate_rv_with_net
                ? machinePrice || 0
                : resultAmount || 0,
            ) /
              100),
        )} %`;
      additionalLabel = {
        formatValue: (valueToFormat: number) =>
          formatNumber(valueToFormat, offer.country.code, {
            withCurrency: true,
            currency: offer.country.currency,
          }),
      };
      break;
    case ESliderOptions.INTEREST_RATE:
      formatValue = (valueToFormat: number) => `${valueToFormat} %`;
      break;
    case ESliderOptions.DOWN_PAYMENT:
      formatValue = (valueToFormat: number) =>
        `${Math.round(
          (Number(valueToFormat) /
            (machinePrice ||
              (offer.machine_price_min + offer.machine_price_max) / 2)) *
            100,
        )} %`;
      additionalLabel = {
        formatValue: (valueToFormat: number) =>
          formatNumber(
            Math.round(Number(valueToFormat) / 100) * 100,
            offer.country.code,
            {
              withCurrency: true,
              currency: offer.country.currency,
            },
          ),
      };
      break;
    case ESliderOptions.MACHINE_PRICE:
      formatValue = (valueToFormat: number) =>
        formatNumber(valueToFormat, offer.country.code, {
          withCurrency: true,
          currency: offer.country.currency,
        });
      break;
    default:
      formatValue = (valueToFormat: number) => `${valueToFormat}`;
  }
  return {
    additionalLabel,
    formatValue,
  };
};
