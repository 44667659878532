import { ThemeProvider } from '@deere/fuel-react';
import '@deere/ux.uxframe-core/dist/css/uxframe.min.css';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Routes from './routes/Routes';

const App = () => (
  <ThemeProvider>
    <section className='flex flex-col w-screen h-screen overflow-auto print-height-full print-width-full'>
      <Header />
      <Helmet>
        <title>John Deere Financial Calculator</title>
      </Helmet>
      <section className='container max-w-screen-xl flex-1'>
        <Routes />
      </section>
      <Footer />
    </section>
  </ThemeProvider>
);

export default App;
