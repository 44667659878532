import React from 'react';

const Triangle = ({
  w = 20,
  h = 20,
  direction = 'right',
}: {
  w?: number;
  h?: number;
  direction?: 'top' | 'left' | 'right' | 'bottom';
}): React.ReactElement => {
  const points = {
    top: [`${w / 2},0`, `0,${h}`, `${w},${h}`],
    right: [`0,0`, `0,${h}`, `${w},${h / 2}`],
    bottom: [`0,0`, `${w},0`, `${w / 2},${h}`],
    left: [`${w},0`, `${w},${h}`, `0,${h / 2}`],
  };

  return (
    <svg width={w} height={h} className='text-jd-green'>
      <polygon points={points[direction].join(' ')} fill='currentColor' />
    </svg>
  );
};

Triangle.defaultProps = {
  w: 20,
  h: 20,
  direction: 'right',
};

export default Triangle;
