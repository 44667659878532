import { ISimulatorValues } from 'types/interfaces/ISimulator';
import { SimulatorState } from 'types/interfaces/ISimulatorState';

// Todo `date` is skipped, check if it is still needed
export default (
  simulator: Partial<SimulatorState>,
): Partial<ISimulatorValues> => ({
  amount: simulator.machinePrice,
  advancePayments: simulator.advancePayments,
  balloon: simulator.balloon,
  downPayment: simulator.downPayment,
  followingPayments: simulator.followingPayments,
  frequency: simulator.paymentForm,
  hours: simulator.operatingHours,
  leasing: simulator.financeType,
  rv: simulator.residualValue,
  term: simulator.term,
  upselling: simulator.upselling,
});
