import { IOffer } from 'types/interfaces/IOffer';
import {
  SimulatorItemState,
  SimulatorState,
} from 'types/interfaces/ISimulatorState';

import { ESliderOptions } from 'utils/offerCalculator/renderSimulation';

export default (
  offer: Pick<
    IOffer,
    'downPaymentMin' | 'downPaymentMax' | 'machinePriceMin' | 'machinePriceMax'
  >,
  simulator: Pick<SimulatorState, ESliderOptions.MACHINE_PRICE>,
): Pick<SimulatorItemState, 'min' | 'max'> => {
  const dpMin1000 = offer
    ? (((simulator.machinePrice ||
        (offer.machinePriceMin + offer.machinePriceMax) / 2) *
        offer.downPaymentMin) /
        1000) *
      1000
    : 0;
  const dpMin100 = offer
    ? (((simulator.machinePrice ||
        (offer.machinePriceMin + offer.machinePriceMax) / 2) *
        offer.downPaymentMin) /
        100) *
      100
    : 0;
  const dpMin = dpMin1000 - dpMin100 > 250 ? dpMin1000 - 500 : dpMin1000;

  // Calculate downpayment ceil in order to have round value
  const dpMax1000 = offer
    ? (((simulator.machinePrice ||
        (offer.machinePriceMin + offer.machinePriceMax) / 2) *
        offer.downPaymentMax) /
        1000) *
      1000
    : 0;
  const dpMax100 = offer
    ? (((simulator.machinePrice ||
        (offer.machinePriceMin + offer.machinePriceMax) / 2) *
        offer.downPaymentMax) /
        100) *
      100
    : 0;
  const dpMax = dpMax1000 - dpMax100 > 250 ? dpMax1000 - 500 : dpMax1000;

  return {
    min: dpMin,
    max: dpMax,
  };
};
