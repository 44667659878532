// @ts-nocheck
import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
  Typography,
} from '@deere/fuel-react';
import { ReactComponent as ChevronLeftIcon } from '@deere/ux.brand-foundations/icons/chevron_left.svg';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import createStore, { Store } from 'store';
// import polyfill for IE11
import 'url-search-params-polyfill';
import createContext from 'zustand/context';

import { fetchSingleOffer } from 'api/api';

import FinancingSimulationCard from 'components/FinancingSimulationCard/FinancingSimulationCard';
import Loader from 'components/Loader/Loader';
import OfferCard from 'components/OfferCard/OfferCard';
import Simulator from 'components/Simulator/Simulator';

import useLocalStorage from 'hooks/useLocalStorage';

import { IApiOffer, IOffer } from 'types/interfaces/IOffer';
import {
  SimulatorState,
  ZSimulatorState,
} from 'types/interfaces/ISimulatorState';

import changeLanguage from 'utils/changeLanguage';
import isLeadCountry from 'utils/isLeadCountry';
import parseCountryCode from 'utils/parseCountryCode';
import {
  checkQueryParamToken,
  checkQueryParamValues,
} from 'utils/queryParamUtils';
import trackData from 'utils/trackData';

const { Provider, useStore } = createContext<Store>();

export const Offer = ({
  setOfferDisclaimer,
  withTracking,
  offer,
  cbyo,
}: {
  setOfferDisclaimer?: (offer: IOffer) => void;
  withTracking?: boolean;
  offer: IOffer;
  cbyo?: boolean;
}) => {
  const { t } = useTranslation();
  const cbyoValue = cbyo && localStorage.getItem('cbyoValue');
  const cbyoUrl = (cbyo && localStorage.getItem('cbyoUrl')) || '';
  const simulator = useStore();
  // TO-DO: remove if not used
  // const [hasLeadGeneration, setHasLeadGeneration] = useState<boolean>(false);
  const [unpublishedOfferRestriction, setUnpublishedOfferRestriction] =
    useState(false);
  const { offerId } = useParams<{ offerId: string }>();
  const [simulatorLS, setSimulatorLS] = useLocalStorage<SimulatorState>(
    `simulation-${offerId}`,
    ZSimulatorState,
  );

  const [simulatorError, setSimulatorError] = useState<string | null>(() =>
    window.localStorage.getItem(`simulation-${offerId}-error`),
  );
  const [showLSBroken, setShowLSBroken] = useState(false);
  const { search } = useLocation();
  const history = useHistory();

  const clearOfferFromLocalStorage = () => {
    window.localStorage.removeItem(`simulation-${offerId}`);
  };
  const reloadPage = () => window.location.reload();
  const getConsultationLink = (): string => `/consultation/${offerId}`;

  useEffect(() => {
    // TO-DO: remove if not used
    // setHasLeadGeneration(isLeadCountry(offer.country.code));

    if (withTracking) {
      const { locale, language, country } = parseCountryCode(
        offer.country.code,
      );

      trackData({
        options: {
          pageNameAddOfferName: true,
          resetDataLayer: true,
        },
        data: {
          event: 'Page Loaded',
          pageCategory: 'offer',
          locale,
          language,
          country,
          breadCrumbs: [
            // not sure if we have to change this, can be removed?
            'servlet',
            'country=US',
          ],
          offerName: offer.title,
        },
      });
    }
    if (setOfferDisclaimer) {
      setOfferDisclaimer(offer);
    }
  }, []);

  useEffect(() => {
    if (
      offer &&
      offer.status !== 'published' &&
      checkQueryParamToken(search) !== `${offer.id}${offer.id}`
    ) {
      setUnpublishedOfferRestriction(true);
    } else {
      setUnpublishedOfferRestriction(false);
    }
  }, [offer, search]);

  useEffect(() => {
    setSimulatorLS(simulator);
  }, [simulator]);

  // If updating the data in local storage and a new error has been discovered and saved,
  //  a modal should be displayed that the data structure is broken.
  useEffect(() => {
    const hasSimulatorError =
      simulatorError !==
      window.localStorage.getItem(`simulation-${offerId}-error`);
    if (hasSimulatorError) {
      setShowLSBroken(true);
      setSimulatorError(
        window.localStorage.getItem(`simulation-${offerId}-error`),
      );
    }
  }, [simulatorLS]);

  const generateTrackData = () => {
    const { locale, language, country } = parseCountryCode(offer?.country.code);
    return {
      options: {
        pageNameAddOfferName: true,
      },
      data: {
        event: 'Complete Calculation Clicked',
        pageCategory: 'offer',
        locale,
        language,
        country,
        breadCrumbs: [
          // not sure if we have to change this, can be removed?
          'servlet',
          'country=US',
        ],
        offerName: offer?.title,
      },
    };
  };

  const getModal = () => (
    <Modal open={showLSBroken} showClose={false} onBackdropClick={() => {}}>
      <ModalTitle>{t('OFFERS.MALFORMED_DATA.TITLE')}</ModalTitle>
      <ModalContent>
        <div className='pb-4'>
          <Typography>{t('OFFERS.MALFORMED_DATA.CONTENT')}</Typography>
        </div>
        <Button
          variant='text'
          onClick={() => {
            clearOfferFromLocalStorage();
            reloadPage();
          }}
        >
          {t('OFFERS.MALFORMED_DATA.BUTTON')}
        </Button>
      </ModalContent>
    </Modal>
  );

  if (unpublishedOfferRestriction) {
    return <Redirect to='/' />;
  }

  const shouldShowBackToOffers = () => {
    if (!cbyo || (cbyo && Number(localStorage.getItem('offersNumber')) > 1)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <section className='my-3'>
        <div className='mb-8'>
          <Typography
            size='4xl'
            component='h1'
            fontWeight='bold'
            gutterBottom
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: t('JOHN_DEERE_SIMULATOR') }}
          />
        </div>
        <section className='my-3 bg-white'>
          <div className='p-3 sm:flex sm:justify-between sm:items-center'>
            <Typography
              size='2xl'
              component='h2'
              fontWeight='bold'
              gutterBottom
            >
              {t('OFFERS.YOUR_OFFER')}
            </Typography>
            {shouldShowBackToOffers() && (
              <button
                className='text-jd-green flex justify-between items-center hover:text-black hidden-print'
                type='button'
                onClick={() => {
                  if (cbyo) {
                    const cbyoUrlParams = localStorage.getItem('cbyoUrlParams');
                    history.push(`/cbyo${cbyoUrlParams}`);
                  } else if (offer) {
                    history.push(`/offers/${offer.country.code}`);
                  }
                }}
              >
                <ChevronLeftIcon className='fill-current' />
                <span className='font-bold'>{t('OFFERS.BACK_OFFERS')}</span>
              </button>
            )}
          </div>
          {offer && (
            <>
              <Helmet>
                <title>{offer.title}</title>
                {/* <meta name='description' content={offer.description} /> */}
                {/* <title><Trans
            i18nKey='META.TITLE'
            values={{
              offer: offer.title
            }}
          /></title> */}
              </Helmet>
              <OfferCard
                title={offer.title}
                subtitle={offer.subtitle}
                description={offer.description}
                publicationEnd={offer.publicationEnd}
                image={offer.imageResized}
                key={offer.id}
                hasSimulationLink={false}
                offerLink={`/${offer.id}`}
              />
            </>
          )}
        </section>
        <section className='block md:flex md:flex-wrap'>
          <section className='flex-1 !mr-0 md:!mr-3 md:max-w-1/2'>
            <Simulator offer={offer} useStore={useStore} cbyo={cbyo} />
          </section>
          <section className='flex-1 !ml-0 md:!ml-3'>
            <FinancingSimulationCard
              topOrientation={false}
              onFinalizeClick={() => {
                trackData(generateTrackData());
                history.push(`/finalize/${offer?.id}`, { cbyo });
              }}
              onConsultationClick={
                isLeadCountry(offer.country.code)
                  ? () => history.push(getConsultationLink())
                  : undefined
              }
              showInterestBox
              useStore={useStore}
            />
            {cbyoValue && cbyoUrl !== '' && (
              <div className='mt-3 flex justify-end'>
                <a href={cbyoUrl}>
                  <button
                    className='px-8 py-3 bg-jd-green text-white font-bold'
                    type='button'
                  >
                    {t('SIMULATOR.BACK_TO_CONFIG')}
                  </button>
                </a>
              </div>
            )}
            {cbyoValue && (
              <div className='flex my-3'>
                <p className='text-xs'>* {t('FOOTER.CBYO')}</p>
              </div>
            )}
          </section>
        </section>
        {offer.disclaimer && (
          <div className='w-full mt-3'>
            <Typography color='tertiary' size='xs' component='p' gutterBottom>
              {offer.disclaimer}
            </Typography>
          </div>
        )}
        <div className='w-full mt-3'>
          {offer.country.disclaimer && (
            <Typography
              color='tertiary'
              size='xs'
              component='p'
              gutterBottom
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{ __html: offer.country.disclaimer }}
            />
          )}
        </div>
        {getModal()}
      </section>
    </>
  );
};

Offer.defaultProps = {
  setOfferDisclaimer: null,
  withTracking: false,
  cbyo: false,
};

const SimpleOffer = ({
  withTracking,
  setOfferDisclaimer,
}: {
  withTracking?: boolean;
  setOfferDisclaimer?: (offer: IOffer) => void;
}) => {
  const { offerId, type } = useParams<{ offerId: string; type?: string }>();
  const { search } = useLocation();
  const [fetchedOffer, setFetchedOffer] =
    useState<{ offer: IApiOffer; mappedOffer: IOffer }>();
  useEffect(() => {
    fetchSingleOffer(Number(offerId)).then(res => {
      setFetchedOffer(res);
      changeLanguage(res.offer.country.code);
    });
    if (type !== 'cbyo') {
      localStorage.removeItem('cbyoValue');
    }
  }, [offerId]);

  return !fetchedOffer ? (
    <div className='flex h-full my-auto'>
      <Loader />
    </div>
  ) : (
    <Provider
      createStore={() =>
        createStore(fetchedOffer.offer, checkQueryParamValues(search))
      }
    >
      <Offer
        withTracking={withTracking}
        setOfferDisclaimer={setOfferDisclaimer}
        offer={fetchedOffer.mappedOffer}
        cbyo={type === 'cbyo'}
      />
    </Provider>
  );
};

SimpleOffer.defaultProps = {
  withTracking: false,
  setOfferDisclaimer: undefined,
};

export default SimpleOffer;
