import { IApiOffer } from 'types/interfaces/IOffer';
import { SimulatorStateBase } from 'types/interfaces/ISimulatorState';

export default (
  balloonPeriod: IApiOffer['balloon_period'],
  term: SimulatorStateBase['term'],
): boolean => {
  if (balloonPeriod && term) {
    // eslint-disable-next-line no-restricted-syntax
    for (const balloonKey in balloonPeriod[term]) {
      if (balloonPeriod[term][balloonKey]) {
        return true;
      }
    }
  }
  return false;
};

export const checkForInterestBasedBalloon = (
  interestBasedOnDownPayment: IApiOffer['interest_based_on_down_payment'],
  machine: SimulatorStateBase['machine'],
): boolean => !!interestBasedOnDownPayment?.interest_based_balloon && !!machine;
