import { ReactComponent as ConversationIcon } from '@deere/ux.brand-foundations/icons/conversation.svg';
import { ReactComponent as UserIcon } from '@deere/ux.brand-foundations/icons/user.svg';
import { css, Global } from '@emotion/react';
import i18n from 'i18n';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import useResizeObserver from 'resize-observer-hook';

import FinancingLabeledNumber from 'components/FinancingSimulationCard/FinancingLabeledNumber';
import FinancingLabeledPrice from 'components/FinancingSimulationCard/FinancingLabeledPrice';
import 'components/FinancingSimulationCard/FinancingSimulationCard.css';
import FinancingSimulationPrintingView from 'components/FinancingSimulationCard/FinancingSimulationPrintingView';

import { IFinancingSimulationCard } from 'types/interfaces/ISimulatorState';

import formatNumber from 'utils/formatNumber';

const MAP_FORM_OF_PAYMENT: { [index: string]: string } = {
  annually: 'SIMULATOR.FORM_OF_PAYMENT_ANNUALLY',
  monthly: 'SIMULATOR.FORM_OF_PAYMENT_MONTHLY',
  quarterly: 'SIMULATOR.FORM_OF_PAYMENT_QUARTERLY',
  semiannually: 'SIMULATOR.FORM_OF_PAYMENT_SEMIANNUALLY',
};

const FinancingSimulationCard = ({
  topOrientation,
  onFinalizeClick,
  onConsultationClick,
  printing,
  showInterestBox,
  useStore,
}: IFinancingSimulationCard) => {
  const { t } = useTranslation();
  const simulator = useStore();

  if (!simulator.result) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ref, elemHeight] = useResizeObserver('borderBoxSize');
  const paymentPeriod = simulator.paymentForm || 'monthly';
  const offer = {
    countryCode: simulator.offer.country.code,
    currency: simulator.offer.country.currency || '',
    showOperatingHours: simulator.offer.show_operating_hours,

    rate: simulator.result.rate,

    mainValues: {
      paymentEveryPeriod: simulator.result.result,
      perOperatingHour: simulator.result.hours,
    },
    resultValues: [
      {
        value: simulator.result.downpayment,
        title: t('SIMULATOR.DOWNPAYENT_IN'),
      },
      {
        value: simulator.result.paymentsNumber,
        title: t('SIMULATOR.NUMBER_PAYMENTS'),
        withCurrency: false,
      },
      {
        value: simulator.result.amount,
        title: t('SIMULATOR.LOAN_AMOUNT'),
      },
      {
        value: simulator.result.openingFee,
        title: t('SIMULATOR.OPENING_FEE'),
      },
      {
        value: simulator.result.advancePayment,
        title: t('SIMULATOR.ADVANCE_PAYMENT'),
      },
      {
        value: simulator.result.showBalloon ? simulator.result.balloon : 0,
        title: t('SIMULATOR.BALLOON'),
      },
      {
        value: simulator.result.showRV ? simulator.result.residualValue : 0,
        title: t('SIMULATOR.RESIDUAL_VALUE'),
      },
    ],
  };

  const cardHeaderOrientation = topOrientation
    ? 'card-header-top'
    : 'card-header-left';

  let rateString = t('SIMULATOR.RATE');

  // exception for italian due to legal reasons
  // see https://gitlab.bitexpert.net/10320-hmf/10320-hmf-1-JohnDeere/10320-hmf-1-JohnDeere-24-FinancialCalculator/10320-hmf-1-JohnDeere-24-FinancialCalculator-3-Dev/-/issues/94
  if (
    i18n.language.toLowerCase() === 'it-it' &&
    simulator.financeType === 'leasing'
  ) {
    rateString = t('SIMULATOR.RATE_LEASING');
  }

  const showRate = simulator.offer?.show_interest_rate;

  const renderLabeledPrices = () =>
    offer.resultValues.map(result => (
      <Fragment key={result.title + result.value}>
        {Boolean(result.value) &&
          (Object.prototype.hasOwnProperty.call(result, 'withCurrency') &&
          result.withCurrency === false ? (
            <FinancingLabeledNumber
              value={result.value}
              countryCode={offer.countryCode}
              title={result.title}
            />
          ) : (
            <FinancingLabeledPrice
              value={result.value}
              countryCode={offer.countryCode}
              currency={offer.currency}
              title={result.title}
            />
          ))}
      </Fragment>
    ));

  return (
    <section>
      {printing && (
        <FinancingSimulationPrintingView
          text={t('SIMULATOR.YOUR_SIMULATION')}
          cardHeaderOrientation={cardHeaderOrientation}
        />
      )}
      <div
        className={`p-6 border-b border-solid ${
          printing ? 'border-jd-black border-opacity-10' : 'border-jd-yellow'
        } relative card-header ${cardHeaderOrientation} grid grid-cols-1 lg:grid-cols-2 lg:gap-4 bg-jd-yellow`}
      >
        {Boolean(offer.mainValues.paymentEveryPeriod) && (
          <div
            className={`self-center${
              offer.mainValues.perOperatingHour
                ? ' mb-1.5 lg:mb-0'
                : ' lg:col-span-2'
            }`}
          >
            <strong className='text-xl'>
              {formatNumber(
                offer.mainValues.paymentEveryPeriod,
                offer.countryCode,
                {
                  withCurrency: true,
                  currency: offer.currency,
                },
              )}
            </strong>
            <h2>
              {paymentPeriod && t(`${MAP_FORM_OF_PAYMENT[paymentPeriod]}`)}
            </h2>
          </div>
        )}
        {Boolean(offer.mainValues.perOperatingHour) &&
          offer.showOperatingHours && (
            <div
              className={`self-center${
                offer.mainValues.paymentEveryPeriod ? ' mt-1.5 lg:mt-0' : ''
              }`}
            >
              <strong className='text-xl'>
                {formatNumber(
                  offer.mainValues.perOperatingHour,
                  offer.countryCode,
                  {
                    withCurrency: true,
                    currency: offer.currency,
                  },
                )}
              </strong>
              <h2>{t('SIMULATOR.PER_OPERATING_HOUR')}</h2>
            </div>
          )}
        {onConsultationClick && (
          <div className='jd-consultation-btn-container hidden-print'>
            <button
              className='flex items-center px-8 py-3 bg-jd-green text-white font-bold'
              type='button'
              onClick={onConsultationClick}
            >
              <UserIcon className='fill-current mr-2' />
              <span>{t('SIMULATOR.CONSULTATION')}</span>
            </button>
          </div>
        )}
        {!onConsultationClick && onFinalizeClick && (
          <div className='jd-consultation-btn-container hidden-print'>
            <button
              className='px-8 py-3 bg-jd-green text-white font-bold'
              type='button'
              onClick={onFinalizeClick}
            >
              {t('SIMULATOR.FINALIZE')}
            </button>
          </div>
        )}
      </div>
      <div className='px-6 py-4 bg-jd-yellow-lighter grid gap-4 grid-cols-2'>
        <div className='col-span-2 grid gap-1'>
          {onFinalizeClick && onConsultationClick && (
            <button
              className='text-jd-green underline chevron-right text-left'
              type='button'
              onClick={onFinalizeClick}
            >
              {t('SIMULATOR.CREATE_PDF_SAVE_LINK')}
            </button>
          )}
        </div>
        {showRate && (
          <div>
            <strong>
              {offer.rate}%
              {simulator.offer?.country.show_a_p_r &&
                typeof simulator.result?.APR === 'number' && (
                  <span>
                    {' '}
                    ({Math.round(simulator.result.APR * 100) / 100}%)
                  </span>
                )}
            </strong>
            <h3 className='opacity-40'>
              {rateString}{' '}
              {simulator.offer?.country.show_a_p_r && t('SIMULATOR.APR')}
            </h3>
          </div>
        )}
        {renderLabeledPrices()}
      </div>
      {showInterestBox && onConsultationClick && (
        <div className='mt-8 p-6 bg-jd-blue-lighter sm:mb-8'>
          <div className='flex'>
            <div className='mr-3 pt-1 text-jd-blue'>
              <ConversationIcon className='fill-current' />
            </div>
            <div>
              <p>{t('SIMULATOR.OFFER_INTEREST')}</p>
              {onConsultationClick && (
                <button
                  className='flex items-center mt-3 px-8 py-3 bg-jd-blue text-white font-bold'
                  type='button'
                  onClick={onConsultationClick}
                >
                  <UserIcon className='fill-current mr-2' />
                  <span>{t('SIMULATOR.CONSULTATION')}</span>
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <>
        <Global
          styles={css`
            @media screen and (max-width: 639px) {
              .footer-section {
                ${`padding-bottom: ${Math.round(elemHeight)}px`}
              }
            }
          `}
        />
        <div
          ref={ref}
          className='px-6 pt-3 pb-6 flex flex-wrap bg-jd-black bg-opacity-10 position-fixed-mobile position-fixed-mobile-offer'
        >
          {Boolean(offer.mainValues.paymentEveryPeriod) && (
            <div className='min-w-financialCardItem py-3'>
              <strong className='text-lg'>
                {formatNumber(
                  offer.mainValues.paymentEveryPeriod,
                  offer.countryCode,
                  {
                    withCurrency: true,
                    currency: offer.currency,
                  },
                )}
              </strong>
              <h3>
                {paymentPeriod && t(`${MAP_FORM_OF_PAYMENT[paymentPeriod]}`)}
              </h3>
            </div>
          )}
          {onConsultationClick && (
            <div className='w-full mt-1 hidden-print'>
              <button
                className='px-8 py-3 bg-jd-green text-white font-bold'
                type='button'
                onClick={onConsultationClick}
              >
                {t('SIMULATOR.CONSULTATION')}
              </button>
            </div>
          )}
          {!onConsultationClick && onFinalizeClick && (
            <div className='w-full mt-1 hidden-print'>
              <button
                className='px-8 py-3 bg-jd-green text-white font-bold'
                type='button'
                onClick={onFinalizeClick}
              >
                {t('SIMULATOR.FINALIZE')}
              </button>
            </div>
          )}
        </div>
      </>
    </section>
  );
};

FinancingSimulationCard.propTypes = {
  topOrientation: PropTypes.bool,
  onFinalizeClick: PropTypes.func,
  onConsultationClick: PropTypes.func,
  printing: PropTypes.bool,
  showInterestBox: PropTypes.bool,
};
FinancingSimulationCard.defaultProps = {
  topOrientation: false,
  onFinalizeClick: null,
  onConsultationClick: null,
  printing: false,
  showInterestBox: false,
};

export default FinancingSimulationCard;
