import { IOffer } from 'types/interfaces/IOffer';
import { SimulatorState } from 'types/interfaces/ISimulatorState';

export default (
  calculateRvWithNet: IOffer['country']['calculateRvWithNet'],
  machinePrice: SimulatorState['machinePrice'],
  amount: SimulatorState['result']['amount'],
  value: number,
): number =>
  ((calculateRvWithNet ? machinePrice || 0 : amount || 0) * value) / 100;
