export default (code: string) => {
  const { hostname } = window.location;
  // localhost is necessary for local development
  const isTal = hostname.includes('.tal.') || hostname.includes('localhost');
  // currently only Germany is one of the pilot countries for the new lead implementation
  const talCountryCodes = ['de_DE', 'pl_PL'];
  const prodCountryCodes = ['de_DE', 'pl_PL'];
  const LEAD_COUNTRY_CODES = isTal ? talCountryCodes : prodCountryCodes;
  return LEAD_COUNTRY_CODES.includes(code);
};
