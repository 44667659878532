/**
 * Helper utility for filtering the address components returned by the Geocoding API by their types.
 * Preserves the order of types (both ['68159', 'Mannheim'] and ['Mannheim', '68159'] are possible results).
 * @param addressComponents
 * @param types
 */
export default (
  addressComponents: google.maps.GeocoderAddressComponent[],
  types: string[] = ['postal_code', 'locality'],
): string[] => {
  const res: string[] = [];
  types.forEach(type =>
    addressComponents.forEach(address => {
      if (address.types.includes(type)) {
        res.push(address.long_name);
      }
    }),
  );
  return res;
};
