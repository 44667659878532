import {
  Checkbox,
  FormControlLabel,
  Modal,
  ModalContent,
  ModalContentText,
  ModalTitle,
} from '@deere/fuel-react';
import { ReactComponent as HelpIcon } from '@deere/ux.brand-foundations/icons/help.svg';
import React, { useState } from 'react';

interface ISimulatorUpselling {
  title?: string;
  productName?: string;
  descriptionTitle?: string;
  description?: string;
  isActive?: boolean;
  onToggleUpselling?: (active: boolean) => void;
}

const SimulatorUpselling = ({
  title,
  productName,
  descriptionTitle,
  description,
  isActive,
  onToggleUpselling,
}: ISimulatorUpselling) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleUpselling = (active: boolean) => {
    if (onToggleUpselling) {
      onToggleUpselling(active);
    }
  };

  return (
    <div className='p-6'>
      <div className='flex flex-col px-4 py-8 border-2 border-jd-green'>
        {title && (
          <div>
            <h3 className='flex justify-center text-center font-bold uppercase text-jd-green'>
              <span className='-mt-10 whitespace-nowrap overflow-hidden text-ellipsis bg-white px-3'>
                {title}
              </span>
            </h3>
          </div>
        )}
        <div>
          <span>
            <FormControlLabel
              label={<p className='font-normal'>{productName}</p> || ''}
              control={
                <Checkbox
                  onClick={e =>
                    // The Checkbox has wrong event type React.MouseEvent<HTMLButtonElement>
                    // where it should be React.ChangeEvenet<HTMLInputElement>, because of this @ts-ignore cannot
                    // be removed
                    // @ts-ignore
                    toggleUpselling(e.target.checked)
                  }
                  checked={isActive}
                />
              }
            />
          </span>
          {descriptionTitle && description && (
            <HelpIcon
              className='inline fill-jd-green cursor-pointer'
              onClick={() => setModalOpen(true)}
            />
          )}
        </div>
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <ModalTitle>{descriptionTitle}</ModalTitle>
          <ModalContent>
            <ModalContentText>{description}</ModalContentText>
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
};
SimulatorUpselling.defaultProps = {
  title: '',
  productName: '',
  descriptionTitle: '',
  description: '',
  isActive: false,
  onToggleUpselling: () => {},
};

export default SimulatorUpselling;
