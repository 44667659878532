import { IOffer } from 'types/interfaces/IOffer';
import { SimulatorState } from 'types/interfaces/ISimulatorState';

/**
 * The dependencies of whether to show or hide the term slider
 * are more complex than the ones of the other sliders.
 * For a better overview, we use this helper util.
 */
const exceptionCountries = ['en_uk'];

export default (
  countryCode: IOffer['country']['code'],
  firstPaymentAdvance: IOffer['firstPaymentAdvance'],
  advancePayments: SimulatorState['advancePayments'],
): boolean => {
  if (
    !firstPaymentAdvance ||
    !exceptionCountries.includes(countryCode.toLowerCase())
  ) {
    return true;
  }
  return (
    firstPaymentAdvance &&
    exceptionCountries.includes(countryCode.toLowerCase()) &&
    !!advancePayments
  );
};
