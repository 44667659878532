import smartlookClient from "smartlook-client";

const Smartlook = () => {
    const getCookie = (cookieName) => {
        const name = `${cookieName}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');

        for (let i = 0; i < cookieArray.length; i+=1) {
            const cookie = cookieArray[i].trim();
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
        return null;
    };

    const InitSmartlook = () => {
        const cookie = getCookie("OptanonConsent");
        let performanceCookieValue = "1";
        let recordScriptFlag;

        if (cookie !== null) {
            cookie.split("&").forEach((element) => {
                if (element.includes("groups=")) {
                    element.split(",").forEach((elmt) => {
                        if (elmt.includes("C0002")) {
                            performanceCookieValue = elmt.split(":");
                        }
                    });
                }
            });

            if (performanceCookieValue[1] === '1') {
                recordScriptFlag = true;
            } else {
                recordScriptFlag = false;
            }
        } else {
            recordScriptFlag = true;
        }

        if (!smartlookClient.initialized() && recordScriptFlag) {
            if (process.env.REACT_APP_ENV==='prod') {
                smartlookClient.init('65ced419997a908a470f318f8dc29d1120257cb6', { region: "eu" });
            } else if (process.env.REACT_APP_ENV==='qual') {
                smartlookClient.init('6192b71677d3a6929a7fb5369c52a66098ac3a5a', { region: "eu" });
            }
        } else if (!recordScriptFlag) {
            smartlookClient.disable(true);
        }
        return null;
    };
    return InitSmartlook();
};

export default Smartlook;