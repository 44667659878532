import { IFormtoolData } from 'types/IFormtoolData';
import { IFormtoolDataBase } from 'types/IFormtoolDataBase';

import trackData from 'utils/trackData';

const PROXY_URL = process.env.REACT_APP_PROXY_URL;
const FORM_KEY = process.env.REACT_APP_FORMTOOL_FORMKEY;

export default async (formData: IFormtoolData) => {
  type QuestionDataEntry = {
    key: string;
    response: string;
  };

  const requestData = {
    questionData: [] as QuestionDataEntry[],
    locale: formData.Locale,
    // needs to be changed depending on hostname
    formKey: FORM_KEY,
    country: formData.Country,
    email: formData.email,
    firstName: formData.firstName,
    lastName: formData.lastName,
  };

  const getFormDataValue = (key: keyof IFormtoolData) =>
    typeof formData[key] !== 'undefined' ? formData[key] : '';

  const setFormData = (
    key: keyof IFormtoolData | keyof IFormtoolDataBase,
    response: string,
  ) =>
    requestData.questionData.push({
      key,
      response,
    });

  if (FORM_KEY === '60960cd4-352e-4f35-a13e-c09a6af6ecb9') {
    // general, this one needs check for qual / prod
    setFormData('optin_online_form', `JDF (qual) (${FORM_KEY})`);
  } else {
    // general, this one needs check for qual / prod
    setFormData('optin_online_form', `JDF (prod) (${FORM_KEY})`);
  }
  // general
  setFormData('optin_eid', 'Region 2');
  setFormData('email_send', 'false');
  setFormData('campaignLabel', 'Finance Calculator');
  setFormData('ActivityTag', 'JDF');
  setFormData('campaignTermsConditions', 'Yes');
  setFormData('localeGeneralMarktConsent', 'Yes');

  // custom form data
  const fieldNames: Array<keyof IFormtoolData> = [
    'Locale',
    'Language',
    'offer_name',
    'submission_URL',
    'submission_url',
    'customer_salutation',
    'Country',
    'customer_company',
    'customer_postcode',
    'customer_street',
    'customer_city',
    'customer_phone',
    'dealer_company',
    'dealer_street',
    'dealer_address',
    'dealer_email',
    'dealer_phone',
    'dealer_website',
    'dealer_locationid',
    'saved_offer_url',
    'guid',
    'currency',
    'finance_product',
    'frequency_of_payment',
    'net_machine_price',
    'deposit_in_euro',
    'years',
    'advance_payment',
    'number_of_following_payments',
    'operating_hours',
    'balloon_payment_residual_value_in_euro',
    'rate_in_percen',
    'loan_amount',
    'administration_fee_in_euro',
    'total_monthly',
    'total_per_operating_hour',
  ];

  // need custom form data
  // requestData.questionData.push({ key: "locale", response: "en_GB" });
  // requestData.questionData.push({ key: "Language", response: "en" });

  fieldNames.forEach((field: keyof IFormtoolData) =>
    setFormData(field, getFormDataValue(field)),
  );

  let status = null;
  let body = null;
  try {
    const response = await fetch(`${PROXY_URL}proxy-request-to-formtool`, {
      body: JSON.stringify({ request: JSON.stringify(requestData) }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });
    status = response.status;
    body = await response.text();

    trackData({
      options: {
        pageNameAddOfferName: true,
      },
      data: {
        event: 'Form Submit',
        pageCategory: 'consultation form',
        locale: formData.Locale,
        language: formData.Language,
        country: formData.Country,
        breadCrumbs: [
          // not sure if we have to change this, can be removed?
          'servlet',
          'country=DE',
        ],
        offerName: formData.offer_name,
      },
    });
  } catch (error) {
    trackData({
      options: {
        pageNameAddOfferName: true,
      },
      data: {
        event: 'Form Error',
        pageCategory: 'consultation form',
        locale: formData.Locale,
        language: formData.Language,
        country: formData.Country,
        breadCrumbs: [
          // not sure if we have to change this, can be removed?
          'servlet',
          'country=DE',
        ],
        offerName: formData.offer_name,
      },
    });

    // probably the backend is not reachable, trigger the same but with a different error message
    throw new Error('some error happened');
  }

  if (status !== 200 && body) {
    throw new Error(body);
  }
};
