import { Typography } from '@deere/fuel-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IDealer } from 'types/interfaces/IDealer';

import DealerSelectorResultItem from './DealerSelectorResultItem';

interface IDealerSelectorResults {
  dealers: IDealer[];
  onDealerSelected: (dealer: IDealer) => void;
}

const DealerSelectorResults = ({
  dealers,
  onDealerSelected,
}: IDealerSelectorResults) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        color='secondary'
        size='md'
        component='p'
        fontWeight='bold'
        className='mb-2'
      >
        {t('DEALER_LOCATOR.SELECTOR.RESULTS.RESULTS')} ({dealers.length})
      </Typography>
      {dealers.length ? (
        dealers.map((dealer, index) => (
          <DealerSelectorResultItem
            key={dealer.locationId}
            dealer={dealer}
            count={index + 1}
            onDealerSelected={onDealerSelected}
          />
        ))
      ) : (
        <Typography color='secondary' size='md' component='p' fontWeight='bold'>
          {t('DEALER_LOCATOR.SELECTOR.RESULTS.NO_RESULTS')}
        </Typography>
      )}
    </>
  );
};

export default DealerSelectorResults;
