import React from 'react';

const CountryContext = React.createContext('');

const useCountry = () => React.useContext(CountryContext);

interface ICountryProvider {
  value: string;
  children: any;
}

const CountryProvider = ({ value, children }: ICountryProvider) => (
  <CountryContext.Provider value={value}>{children}</CountryContext.Provider>
);

export { CountryProvider, useCountry };
