// @ts-nocheck
// https://jdfcalculator.tal.deere.com/cbyo?make_name=value&division_name=value&platform_name=value&product_family_name=value&product_family_subcategory=value&product_line_name=value&series_name=value&decal_model_name=value&decal_model_classification_type=value&locale=value&currency=value&options=value&tax_included=value&attachements_configured=value&number_of_configurations=value&base_price=value&options_price=value&attachments_price=value&total_price=value&cbyo_link=value
import { Typography } from '@deere/fuel-react';
import qs, { ParsedQs } from 'qs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
// import polyfill for IE11
import 'url-search-params-polyfill';

import { fetchCBYOOffers } from 'api/api';

import Loader from 'components/Loader/Loader';
import OfferCard from 'components/OfferCard/OfferCard';

import { IOffer } from 'types/interfaces/IOffer';

/* eslint-disable @typescript-eslint/naming-convention */

export const CbyoHiddenPage = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const queryObject = qs.parse(search, { ignoreQueryPrefix: true });
  const [isLoading, setIsLoading] = useState(true);
  const [offers, setOffers] = useState<IOffer[]>([]);
  const history = useHistory();

  const cbyo_url = queryObject.cbyo_link || '';

  const sumArray = (array: number[]): number =>
    array.reduce((a: number, b: number) => Number(a) + Number(b), 0);

  const parseArray = (array: string[] | ParsedQs[]): number[] =>
    array.map((item: string | ParsedQs) => Number(item));

  const total_price = Array.isArray(queryObject.total_price)
    ? sumArray(parseArray(queryObject.total_price))
    : queryObject.total_price;

  localStorage.setItem('cbyoUrl', cbyo_url.toString());

  if (total_price) {
    localStorage.setItem(
      'cbyoValue',
      total_price > 1000 ? total_price.toString() : '1000',
    );
  }

  localStorage.setItem('cbyoUrlParams', search);

  useEffect(() => {
    const baseCode = queryObject.basecode?.toString() || '';
    let locale = queryObject.locale?.toString() || 'en_UK';

    if (locale === 'en_GB') {
      locale = 'en_UK';
    }

    fetchCBYOOffers(locale, baseCode).then(fetchedOffers => {
      setIsLoading(false);
      localStorage.setItem('offersNumber', fetchedOffers.length.toString());
      // delete old simulations to reset the form elements
      fetchedOffers.forEach(fetchedOffer =>
        window.localStorage.removeItem(`simulation-${fetchedOffer.id}`),
      );
      if (fetchedOffers.length === 1) {
        history.push(`/${fetchedOffers[0].id}/cbyo`);
      } else {
        setOffers(fetchedOffers);
      }
    });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <section className='grid grid-cols-1 gap-0 gap-y-8 sm:gap-8 sm:grid-cols-2 my-3'>
      <div className='w-full col-span-2'>
        <Typography
          size='4xl'
          component='h1'
          fontWeight='bold'
          gutterBottom
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{ __html: t('JOHN_DEERE_SIMULATOR') }}
        />
      </div>
      {Boolean(offers.length) &&
        offers.map(offer => (
          <OfferCard
            withTracking
            title={offer.title}
            subtitle={offer.subtitle}
            description={offer.description}
            publicationEnd={offer.publicationEnd}
            image={offer.imageResized}
            isLink
            key={offer.id}
            countryCode={offer.country.code}
            offerLink={`/${offer.id}/cbyo`}
          />
        ))}
      {!offers.length && (
        <div className='my-5'>
          <Typography size='xs' component='p' gutterBottom>
            {t('OFFERS.NORESULTS')}
          </Typography>
        </div>
      )}
      <div className='w-full col-span-2'>
        <Typography
          color='tertiary'
          size='xs'
          component='p'
          gutterBottom
          dangerouslySetInnerHTML={{ __html: t('FOOTER.DISCLAIMER') }}
        />
      </div>
    </section>
  );
};

CbyoHiddenPage.defaultProps = {};

export default CbyoHiddenPage;
