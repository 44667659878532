import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import atTranslations from './i18n/at.json';
import bgTranslations from './i18n/bg.json';
import czTranslations from './i18n/cz.json';
import deTranslations from './i18n/de.json';
import de_atTranslations from './i18n/de_at.json';
import de_chTranslations from './i18n/de_ch.json';
import de_luTranslations from './i18n/de_lu.json';
import dkTranslations from './i18n/dk.json';
import eeTranslations from './i18n/ee.json';
import enTranslations from './i18n/en.json';
import esTranslations from './i18n/es.json';
import fiTranslations from './i18n/fi.json';
import frTranslations from './i18n/fr.json';
import fr_beTranslations from './i18n/fr_be.json';
import fr_chTranslations from './i18n/fr_ch.json';
import hrTranslations from './i18n/hr.json';
import itTranslations from './i18n/it.json';
import ltTranslations from './i18n/lt.json';
import lvTranslations from './i18n/lv.json';
import nlTranslations from './i18n/nl.json';
import nl_beTranslations from './i18n/nl_be.json';
import plTranslations from './i18n/pl.json';
import ptTranslations from './i18n/pt.json';
import siTranslations from './i18n/si.json';
import svTranslations from './i18n/sv.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // JD sets the language in the meta tag of the head
    lng: document.head.querySelector('meta[name="language"]')
      ? // @ts-ignore
        document.head.querySelector('meta[name="language"]').content
      : 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    resources: {
      at: {
        translation: atTranslations,
      },
      bg: {
        translation: bgTranslations,
      },
      cz: {
        translation: czTranslations,
      },
      'cs-CZ': {
        translation: czTranslations,
      },
      'de-AT': {
        translation: de_atTranslations,
      },
      'de-CH': {
        translation: de_chTranslations,
      },
      'de-LU': {
        translation: de_luTranslations,
      },
      de: {
        translation: deTranslations,
      },
      'da-DK': {
        translation: dkTranslations,
      },
      dk: {
        translation: dkTranslations,
      },
      'et-EE': {
        translation: eeTranslations,
      },
      ee: {
        translation: eeTranslations,
      },
      en: {
        translation: enTranslations,
      },
      es: {
        translation: esTranslations,
      },
      fi: {
        translation: fiTranslations,
      },
      fr: {
        translation: frTranslations,
      },
      'fr-BE': {
        translation: fr_beTranslations,
      },
      'fr-CH': {
        translation: fr_chTranslations,
      },
      hr: {
        translation: hrTranslations,
      },
      it: {
        translation: itTranslations,
      },
      lt: {
        translation: ltTranslations,
      },
      lv: {
        translation: lvTranslations,
      },
      'nl-BE': {
        translation: nl_beTranslations,
      },
      nl: {
        translation: nlTranslations,
      },
      pl: {
        translation: plTranslations,
      },
      pt: {
        translation: ptTranslations,
      },
      'sl-SI': {
        translation: siTranslations,
      },
      si: {
        translation: siTranslations,
      },
      sv: {
        translation: svTranslations,
      },
    },
  });

export default i18n;
