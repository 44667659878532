/**
 * The values of this enum are used if interestBasedOnDownPayment is set in offers.
 *  They represent percentage values for the down payment that are displayed
 *  in a radio group (instead of a slider).
 */
enum EFixedDownPaymentRates {
  'PERCENT_10' = '10',
  'PERCENT_35' = '35',
  'PERCENT_50' = '50',
}

export default EFixedDownPaymentRates;
