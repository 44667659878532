import { ZodIssue } from 'zod';
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { IApiOffer } from 'types/interfaces/IOffer';
import { ISimulatorValues } from 'types/interfaces/ISimulator';
import {
  SimulatorState,
  ZSimulatorState,
} from 'types/interfaces/ISimulatorState';

import renderSimulation from 'utils/offerCalculator/renderSimulation';

export interface Store extends SimulatorState {
  updateSimulator: (newState: Partial<SimulatorState>) => void;
}

// @Todo export can be removed once we got rid of the useLocalStorage
export const storeValidationError = (key: string, issues: ZodIssue[]): void => {
  // for future time issues, we should use a time library
  const d = new Date();
  // generates date like yyyyMMdd-HHmmss
  const dateString = `${d.getFullYear()}${`0${d.getMonth() + 1}`.slice(
    -2,
  )}${`0${d.getDate()}`.slice(-2)}-${`0${d.getHours()}`.slice(
    -2,
  )}${`0${d.getMinutes()}`.slice(-2)}${`0${d.getSeconds()}`.slice(-2)}`;
  let newValidationError = { [`${dateString}`]: issues };
  const currentErrors = window.localStorage.getItem(`${key}-error`);
  const currentErrorsObject = currentErrors ? JSON.parse(currentErrors) : null;
  // do not replace old error but add to stack
  if (currentErrorsObject) {
    newValidationError = {
      ...currentErrorsObject,
      ...newValidationError,
    };
  }
  window.localStorage.setItem(
    `${key}-error`,
    JSON.stringify(newValidationError),
  );
};

const createStore = (offer: IApiOffer, queryParamValues?: ISimulatorValues) => {
  const localStorage = window.localStorage.getItem(`simulation-${offer.id}`);
  const simulatorStateFromLocalStorage = localStorage
    ? JSON.parse(localStorage)
    : undefined;
  return create<Store>(
    devtools(
      persist(
        (set): Store => ({
          ...renderSimulation(
            offer,
            simulatorStateFromLocalStorage,
            queryParamValues,
          ),
          // It should be possible to change anything:
          //  The state rendering function calculates accordingly.
          updateSimulator: newState =>
            set(state =>
              renderSimulation(offer, {
                ...state,
                ...newState,
              }),
            ),
        }),
        {
          name: `simulation-${offer.id}`, // key in local storage
          // Care #217: The default storage of store is local storage but the data structure
          // can not be changed. By default, the structure is {state: {...state}, version: x})
          // which is not compliant with the initial state data structure {...state}
          getStorage: () => ({
            getItem: async (name: string): Promise<string | null> =>
              window.localStorage.getItem(name),
            setItem: async (name: string, value: string): Promise<void> => {
              // @Todo we should handle this case with a try catch block!
              const valueJson = JSON.parse(value);
              // validate before persisting the state to local storage!
              const zodParsed = ZSimulatorState.safeParse(valueJson.state);
              if (!zodParsed.success) {
                storeValidationError(
                  `simulation-${offer.id}`,
                  zodParsed.error.issues,
                );
              } else {
                window.localStorage.setItem(
                  name,
                  JSON.stringify({ ...valueJson.state }),
                );
              }
            },
            removeItem: async (name: string): Promise<void> => {
              window.localStorage.removeItem(name);
            },
          }),
        },
      ),
    ),
  );
};

export default createStore;
