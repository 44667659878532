enum EMachines {
  MACHINE_1 = 'machine_1',
  MACHINE_2 = 'machine_2',
  MACHINE_3 = 'machine_3',
  MACHINE_4 = 'machine_4',
  MACHINE_5 = 'machine_5',
  MACHINE_6 = 'machine_6',
  MACHINE_7 = 'machine_7',
  MACHINE_8 = 'machine_8',
  MACHINE_9 = 'machine_9',
  MACHINE_10 = 'machine_10',
}

export default EMachines;
