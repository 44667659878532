import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@deere/fuel-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Smartlook from 'components/Smartlook/Smartlook';

import parseCountryCode from 'utils/parseCountryCode';
import trackData from 'utils/trackData';

import './OfferCard.css';

type OfferCardProps = {
  title: string;
  subtitle: string;
  description: string;
  // publicationEnd was changed to optional because
  // the CBYO Fallback offer doesn't have publicationStart and publicationEnd fields
  publicationEnd?: Date;
  image: string;
  offerLink: string;
  headline?: string;
  isLink?: boolean;
  hasSimulationLink?: boolean;
  withTracking?: boolean;
  countryCode?: string;
};

const OfferCard = ({
  title,
  subtitle,
  description,
  publicationEnd,
  image,
  headline,
  isLink,
  hasSimulationLink,
  withTracking,
  countryCode,
  offerLink,
}: OfferCardProps) => {
  const { t, i18n } = useTranslation();
  const trackClick = (trackCountryCode: string, trackTitle: string) => {
    const { locale, language, country } = parseCountryCode(trackCountryCode);

    trackData({
      options: {
        pageNameWithoutPageCategoryAndOfferName: true,
      },
      data: {
        event: 'Offer Clicked',
        pageCategory: 'homepage',
        locale,
        language,
        country,
        offerName: trackTitle,
        breadCrumbs: [
          // not sure if we have to change this, can be removed?
          'servlet',
          'country=US',
        ],
      },
    });
  };

  const mainContent: JSX.Element = (
    <Card
      sx={{
        borderRadius: 0,
        mt: 6,
      }}
    >
      <Smartlook />
      <Grid container>
        {headline && (
          <div className='w-full'>
            <Grid>
              <CardContent>
                <Typography size='xl' fontWeight='bold' component='h2'>
                  {headline}
                </Typography>
              </CardContent>
            </Grid>
          </div>
        )}
        <Grid item className='hidden-print'>
          <CardMedia className='h-full' image={image} title='Deere equipment' />
        </Grid>
        <Grid item>
          <Box display='flex' height='100%' flexDirection='column'>
            <CardContent>
              <Typography
                gutterBottom
                size='md'
                fontWeight='bold'
                component={isLink ? 'h2' : 'h3'}
              >
                {title}
              </Typography>
              <Typography
                color='secondary'
                size='md'
                component='p'
                fontWeight='bold'
              >
                {subtitle}
              </Typography>
              <Typography
                gutterBottom
                color='secondary'
                size='md'
                component='p'
              >
                {description}
              </Typography>
              <Box mt={2}>
                <Typography
                  color='secondary'
                  size='md'
                  component='p'
                  fontWeight='bold'
                >
                  {t('OFFERS.OFFER_DURATION')}
                </Typography>
                {publicationEnd && (
                  <Typography
                    gutterBottom
                    color='secondary'
                    size='md'
                    component='p'
                  >
                    {publicationEnd.toLocaleDateString(i18n.language, {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                    })}
                  </Typography>
                )}
              </Box>
            </CardContent>
            {hasSimulationLink && (
              <Box mt='auto'>
                <CardActions bgcolor='transparent' justifyContent='flex-end'>
                  <Button variant='primary'>
                    {t('OFFERS.BTN_SIMULATION')}
                  </Button>
                </CardActions>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );

  return (
    <>
      {isLink ? (
        <Link
          to={offerLink}
          className='offer-card col-span-2'
          onClick={() => withTracking && trackClick(countryCode ?? '', title)}
        >
          {mainContent}
        </Link>
      ) : (
        <div className='offer-card'>{mainContent}</div>
      )}
    </>
  );
};

OfferCard.defaultProps = {
  headline: '',
  isLink: false,
  hasSimulationLink: true,
  withTracking: false,
  countryCode: '',
  publicationEnd: undefined,
};

export default OfferCard;
