// @ts-nocheck
import { Button, Card, Typography } from '@deere/fuel-react';
import { ReactComponent as CloseIcon } from '@deere/ux.brand-foundations/icons/close.svg';
import { ReactComponent as DownloadIcon } from '@deere/ux.brand-foundations/icons/download.svg';
import { ReactComponent as UserIcon } from '@deere/ux.brand-foundations/icons/user.svg';
// needed for changing the language on demand
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import createStore, { Store } from 'store';
// import polyfill for IE11
import 'url-search-params-polyfill';
import createContext from 'zustand/context';

import { fetchSingleOffer } from 'api/api';

import FinancingSimulationCard from 'components/FinancingSimulationCard/FinancingSimulationCard';
import Loader from 'components/Loader/Loader';
import OfferCard from 'components/OfferCard/OfferCard';
import SaveCalculation from 'components/SaveCalculation/SaveCalculation';
import Simulator from 'components/Simulator/Simulator';

import EFinanceTypes from 'types/enums/EFinanceTypes';
import { IApiOffer, IOffer } from 'types/interfaces/IOffer';

import changeLanguage from 'utils/changeLanguage';
import isLeadCountry from 'utils/isLeadCountry';
import parseCountryCode from 'utils/parseCountryCode';
import {
  checkQueryParamToken,
  checkQueryParamValues,
} from 'utils/queryParamUtils';
import trackData from 'utils/trackData';

import './Finalize.css';

const { Provider, useStore } = createContext<Store>();

const Finalize = ({ offer }: { offer: IOffer }) => {
  const { t } = useTranslation();
  const simulator = useStore();
  const history = useHistory();
  const [unpublishedOfferRestriction, setUnpublishedOfferRestriction] =
    useState(false);
  const { search, state } = useLocation();
  const { offerId } = useParams<{ offerId: string }>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasLeadGeneration, setHasLeadGeneration] = useState<boolean>(false);
  const [printing, setPrinting] = useState<boolean>(false);
  const [cbyo, setCbyo] = useState<boolean>(false);

  const trackClick = (mappedOffer: IOffer) => {
    const { locale, language, country } = parseCountryCode(
      mappedOffer.country.code,
    );

    trackData({
      options: {
        pageNameAddOfferName: true,
      },
      data: {
        event: 'Find Sales Partners',
        pageCategory: 'finalize',
        locale,
        language,
        country,
        breadCrumbs: [
          // not sure if we have to change this, can be removed?
          'servlet',
          'country=DE',
        ],
        offerName: mappedOffer.title,
      },
    });
  };

  const trackPrintClick = (countryCode: string, mappedOffer: IOffer) => {
    const { locale, language, country } = parseCountryCode(countryCode);

    trackData({
      options: {
        pageNameAddOfferName: true,
      },
      data: {
        event: 'Print',
        pageCategory: 'finalize',
        locale,
        language,
        country,
        breadCrumbs: [
          // not sure if we have to change this, can be removed?
          'servlet',
          'country=DE',
        ],
        offerName: mappedOffer.title,
      },
    });
  };

  useEffect(() => {
    window.onafterprint = () => {
      setPrinting(false);
    };
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (state && state.cbyo) {
      setCbyo(true);
      return;
    }
    setCbyo(false);
  }, [state]);

  useEffect(() => {
    if (printing) {
      window.print();
    }
  }, [printing]);

  useEffect(() => {
    if (offer.country.code) {
      setHasLeadGeneration(isLeadCountry(offer.country.code));
    }

    const { locale, language, country } = parseCountryCode(offer.country.code);

    trackData({
      options: {
        pageNameAddOfferName: true,
        resetDataLayer: true,
      },
      data: {
        event: 'Page Loaded',
        pageCategory: 'offer finalize',
        locale,
        language,
        country,
        breadCrumbs: [
          // not sure if we have to change this, can be removed?
          'servlet',
          'country=US',
        ],
        offerName: offer.title,
      },
    });
  }, []);

  useEffect(() => {
    if (
      offer &&
      offer.status !== 'published' &&
      checkQueryParamToken(search) !== `${offer.id}${offer.id}`
    ) {
      setUnpublishedOfferRestriction(true);
    } else {
      setUnpublishedOfferRestriction(false);
    }
  }, [offer, search]);

  if (unpublishedOfferRestriction) {
    return <Redirect to='/' />;
  }

  return (
    <>
      <div className='my-3'>
        <div className='mb-8'>
          <Typography
            size='4xl'
            component='h1'
            fontWeight='bold'
            gutterBottom
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: t('JOHN_DEERE_SIMULATOR') }}
          />
        </div>
        {offer ? (
          <>
            <Helmet>
              <title>{offer.title}</title>
              {/* <meta name='description' content={offer.description} /> */}
              {/* <title><Trans
              i18nKey='META.TITLE'
              values={{
                offer: offer.title
              }}
            /></title> */}
            </Helmet>
            <section className='my-4 hidden-print'>
              <Card>
                <div className='m-6'>
                  <div className='sm:flex sm:flex-wrap sm:justify-between sm:items-center'>
                    <h2 className='font-bold text-lg'>
                      {t('SIMULATOR.WHAT_NEXT')}
                    </h2>
                    <Button
                      onClick={() =>
                        history.push(`/${offerId}${cbyo ? '/cbyo' : ''}`)
                      }
                      className='sm:float-right p-0 mt-3 sm:mt-0'
                      variant='text'
                      endIcon={<CloseIcon className='fill-current' />}
                    >
                      {t('SIMULATOR.BACK_SIMULATION')}
                    </Button>
                  </div>
                </div>
                <div className='m-6 sm:flex sm:flex-wrap gap-x-8'>
                  {!isLeadCountry(offer.country.code) ? (
                    <a
                      href={`https://dealerlocator.deere.com/servlet/country=${
                        (offer.country.code === 'en_UK'
                          ? 'en_GB'
                          : offer.country.code
                        ).split('_')[1]
                      }?locale=${offer.country.code}`}
                      target='_blank'
                      rel='noreferrer noopener'
                      data-linkid='cta : finalize : Find a dealer'
                      data-linktype='internal-immediate'
                      onClick={() => trackClick(offer)}
                    >
                      <button
                        className='px-8 py-3 bg-jd-green text-white font-bold'
                        type='button'
                      >
                        {t('SIMULATOR.FIND_DEALER')}
                      </button>
                    </a>
                  ) : (
                    <button
                      className='flex items-center px-8 py-3 bg-jd-green text-white font-bold'
                      type='button'
                      onClick={() => history.push(`/consultation/${offerId}`)}
                    >
                      <UserIcon className='fill-current mr-2' />
                      <span>{t('SIMULATOR.CONSULTATION')}</span>
                    </button>
                  )}
                  <a
                    href='#print'
                    className='flex mt-3 sm:mt-0'
                    data-linkid='cta : finalize : Print'
                    data-linktype='internal-immediate'
                  >
                    <Button
                      variant='text'
                      startIcon={<DownloadIcon className='fill-current' />}
                      onClick={() => {
                        trackPrintClick(offer.country.code, offer);
                        setPrinting(true);
                      }}
                    >
                      {t('SIMULATOR.PRINT')}
                    </Button>
                  </a>

                  <SaveCalculation
                    countryCode={offer.country.code}
                    title={offer.title}
                    useStore={useStore}
                  />
                </div>
              </Card>
            </section>
            <section className='my-4 flex flex-wrap print-layout finalize-page'>
              <section className='w-full md:w-1/3 !pr-0 md:!pr-3 my-1 print-width-full'>
                <OfferCard
                  title={offer.title}
                  subtitle={offer.subtitle}
                  description={offer.description}
                  publicationEnd={offer.publicationEnd}
                  image={offer.imageResized}
                  headline={
                    simulator.financeType === EFinanceTypes.LEASING
                      ? t('FINALIZE.FINANCE_TYPE_LEASING')
                      : t('FINALIZE.FINANCE_TYPE_INSTALLMENT')
                  }
                  key={offer.id}
                  hasSimulationLink={false}
                  offerLink={`/${offer.id}`}
                />
              </section>
              <section className='w-full md:w-2/3 !pl-0 md:!pl-3 my-1 simulator-print-layout print-width-full'>
                <section>
                  <Simulator offer={offer} disabled useStore={useStore} />
                </section>
                <section className='financial-card-print-layout'>
                  <FinancingSimulationCard
                    topOrientation
                    onConsultationClick={
                      isLeadCountry(offer.country.code)
                        ? () => history.push(`/consultation/${offerId}`)
                        : undefined
                    }
                    printing={printing}
                    useStore={useStore}
                  />
                </section>
              </section>
            </section>
            {offer.disclaimer && (
              <div className='w-full mt-3'>
                <Typography
                  color='tertiary'
                  size='xs'
                  component='p'
                  gutterBottom
                >
                  {offer.disclaimer}
                </Typography>
              </div>
            )}
            <div className='w-full mt-3'>
              <Typography color='tertiary' size='xs' component='p' gutterBottom>
                {offer.country.disclaimer && (
                  <div
                    // eslint-disable-next-line
                    dangerouslySetInnerHTML={{
                      __html: offer.country.disclaimer,
                    }}
                  />
                )}
              </Typography>
            </div>
          </>
        ) : (
          <em>No simulator set</em>
        )}
      </div>
    </>
  );
};

const FinalizeWithProvider = () => {
  const { offerId } = useParams<{ offerId: string }>();
  const { search } = useLocation();
  const [fetchedOffer, setFetchedOffer] =
    useState<{ offer: IApiOffer; mappedOffer: IOffer }>();
  useEffect(() => {
    fetchSingleOffer(Number(offerId)).then(res => {
      setFetchedOffer(res);
      changeLanguage(res.offer.country.code);
    });
  }, [offerId]);

  return !fetchedOffer ? (
    <div className='flex h-full my-auto'>
      <Loader />
    </div>
  ) : (
    <Provider
      createStore={() =>
        createStore(fetchedOffer.offer, checkQueryParamValues(search))
      }
    >
      <Finalize offer={fetchedOffer.mappedOffer} />
    </Provider>
  );
};

export default FinalizeWithProvider;
