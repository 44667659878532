import { MenuItem, Radio, Select } from '@deere/fuel-react';
import { ReactComponent as CloseIcon } from '@deere/ux.brand-foundations/icons/close.svg';
import { FormControlLabel, RadioGroup } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'store';
import { UseContextStore } from 'zustand/context';
import shallow from 'zustand/shallow';

import EFinanceTypes from 'types/enums/EFinanceTypes';
import EMachines from 'types/enums/EMachines';
import { IOffer } from 'types/interfaces/IOffer';

import {
  EAdditionalOptions,
  ERadioOptions,
  ESelectOptions,
  ESliderOptions,
} from 'utils/offerCalculator/renderSimulation';

import './Simulator.css';
import SimulatorItem from './SimulatorItem';
import SimulatorUpselling from './SimulatorUpselling';

type SimulatorProps = {
  offer?: IOffer;
  disabled?: boolean;
  onCloseSimulation?: () => void;
  useStore: UseContextStore<Store>;
  cbyo?: boolean;
};

const Simulator = ({
  offer,
  disabled,
  onCloseSimulation,
  useStore,
  cbyo,
}: SimulatorProps) => {
  const cbyoValue = cbyo && localStorage.getItem('cbyoValue');
  const { t } = useTranslation();
  const {
    radioOptions,
    sliderOptions,
    selectOptions,
    upselling,
    updateSimulator,
  } = useStore(
    state => ({
      radioOptions: state.radioOptions,
      selectOptions: state.selectOptions,
      sliderOptions: state.sliderOptions,
      upselling: state.upselling,
      updateSimulator: state.updateSimulator,
    }),
    shallow,
  );

  const onAdditionalOptionsChangeCallback = useCallback(
    /* @ts-ignore */
    (name: EAdditionalOptions, value) => {
      updateSimulator({
        [name]: value,
      });
    },
    [],
  );

  const onRadioGroupChangeCallback = useCallback(
    (name: ERadioOptions | ESliderOptions.DOWN_PAYMENT, value: string) => {
      updateSimulator({
        [name]: value,
        radioOptions: {
          ...radioOptions,
          [name]: {
            ...radioOptions[name],
            value,
          },
        },
      });
    },
    [radioOptions],
  );

  const onSelectChangeCallback = useCallback(
    (name: ESelectOptions, value: EMachines) => {
      updateSimulator({
        [name]: value,
        // @ts-ignore this is currently not handling the case that selectOptions might be undefined
        // which is practically impossible as the callback function relies on selectOptions being set
        selectOptions: {
          ...selectOptions,
          ...(selectOptions &&
            selectOptions[name] && {
              [name]: {
                ...(selectOptions && selectOptions[name]),
                value,
              },
            }),
        },
      });
    },
    [selectOptions],
  );

  if (!offer) {
    return null;
  }

  const getItemLabel = (item: { label: string; value: string }): string =>
    t(item.label);

  return (
    <section className='simulator bg-white'>
      <div className='p-6 border-b border-solid border-jd-black border-opacity-10 sm:flex sm:flex-wrap sm:justify-between sm:items-center'>
        <h4 className='font-bold text-lg'>{t('SIMULATOR.INPUT')}</h4>
        {onCloseSimulation && (
          <button
            className='text-jd-green underline flex justify-between items-center mt-3 sm:mt-0'
            type='button'
            onClick={onCloseSimulation}
          >
            <span className='font-bold'>{t('SIMULATOR.CLOSE_COMPARATOR')}</span>
            <CloseIcon className='fill-current' />
          </button>
        )}
      </div>
      <div className='p-6 flex flex-wrap justify-between items-start radio-container'>
        <div className='flex-1'>
          <h3 className='font-bold uppercase opacity-40'>
            {t('SIMULATOR.FINANCE_TYPE')}
          </h3>
          {radioOptions?.financeType && (
            <RadioGroup
              aria-label={t('SIMULATOR.FINANCE_TYPE')}
              defaultValue={radioOptions.financeType.defaultValue}
              onChange={event => {
                if (radioOptions.financeType) {
                  onRadioGroupChangeCallback(
                    radioOptions.financeType.name,
                    event.target.value,
                  );
                }
              }}
            >
              {radioOptions?.financeType &&
                radioOptions?.financeType.items &&
                radioOptions?.financeType.items.map(
                  (item: { label: string; value: string }) => (
                    <FormControlLabel
                      key={item.value}
                      value={item.value}
                      checked={item.value === radioOptions.financeType.value}
                      control={<Radio />}
                      label={getItemLabel(item)}
                      disabled={disabled}
                    />
                  ),
                )}
            </RadioGroup>
          )}
        </div>
        <div className='flex-1'>
          <h3 className='font-bold uppercase opacity-40'>
            {t('SIMULATOR.FORM_OF_PAYMENT')}
          </h3>
          {radioOptions?.paymentForm && (
            <RadioGroup
              aria-label={t('SIMULATOR.FORM_OF_PAYMENT')}
              defaultValue={radioOptions.paymentForm.defaultValue}
              onChange={event => {
                if (radioOptions.paymentForm) {
                  onRadioGroupChangeCallback(
                    radioOptions.paymentForm.name,
                    event.target.value,
                  );
                }
              }}
            >
              {radioOptions?.paymentForm.items &&
                radioOptions?.paymentForm.items.map(
                  (item: { label: string; value: string }) => (
                    <FormControlLabel
                      key={item.value}
                      value={item.value}
                      checked={item.value === radioOptions.paymentForm.value}
                      control={<Radio />}
                      label={getItemLabel(item)}
                      disabled={disabled}
                    />
                  ),
                )}
            </RadioGroup>
          )}
        </div>
      </div>
      {offer.interestBasedOnDownPayment && (
        <div className='p-6 flex flex-wrap justify-between items-start radio-container'>
          <div className='flex-1'>
            <h3 className='font-bold uppercase opacity-40'>
              {t('SIMULATOR.DOWNPAYMENT')}
            </h3>
            {radioOptions?.downPayment && (
              <RadioGroup
                aria-label={t('SIMULATOR.DOWNPAYMENT')}
                defaultValue={radioOptions.downPayment.defaultValue}
                onChange={event => {
                  if (radioOptions.downPayment) {
                    onRadioGroupChangeCallback(
                      radioOptions.downPayment.name,
                      event.target.value,
                    );
                  }
                }}
              >
                {radioOptions?.downPayment.items &&
                  radioOptions?.downPayment.items.map(
                    (item: { label: string; value: string }) => (
                      <FormControlLabel
                        key={item.value}
                        value={item.value}
                        checked={item.value === radioOptions.downPayment?.value}
                        control={<Radio />}
                        // @ts-ignore
                        label={getItemLabel(item)}
                        disabled={disabled}
                      />
                    ),
                  )}
              </RadioGroup>
            )}
          </div>
          {offer.interestBasedOnDownPayment?.interestBasedBalloon &&
            radioOptions.financeType.value === EFinanceTypes.PURCHASE &&
            !!selectOptions?.machine?.items.length && (
              <div className='flex-1'>
                <h3 className='font-bold uppercase opacity-40'>
                  {t('SIMULATOR.MACHINE')}
                </h3>
                {selectOptions?.machine && (
                  <Select
                    className='w-full pt-2'
                    defaultValue={selectOptions.machine.defaultValue}
                    value={selectOptions.machine.value}
                    onChange={event => {
                      if (selectOptions.machine) {
                        onSelectChangeCallback(
                          selectOptions.machine.name,
                          event.target.value as EMachines,
                        );
                      }
                    }}
                  >
                    {selectOptions.machine.items.map(item => (
                      /* @ts-ignore */
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
            )}
        </div>
      )}
      {sliderOptions[ESliderOptions.MACHINE_PRICE] && (
        <SimulatorItem
          key={ESliderOptions.MACHINE_PRICE}
          sliderItemOptions={sliderOptions[ESliderOptions.MACHINE_PRICE]}
          disabled={disabled}
          useStore={useStore}
          cbyoValue={Number(cbyoValue)}
        />
      )}
      {!offer.interestBasedOnDownPayment &&
        sliderOptions[ESliderOptions.DOWN_PAYMENT] && (
          <SimulatorItem
            key={ESliderOptions.DOWN_PAYMENT}
            sliderItemOptions={sliderOptions[ESliderOptions.DOWN_PAYMENT]}
            disabled={disabled}
            useStore={useStore}
          />
        )}
      {offer.showInterestRate &&
        offer.allowUserPreferenceForInterestRate &&
        sliderOptions[ESliderOptions.INTEREST_RATE] && (
          <SimulatorItem
            key={ESliderOptions.INTEREST_RATE}
            sliderItemOptions={sliderOptions[ESliderOptions.INTEREST_RATE]}
            disabled={disabled}
            useStore={useStore}
          />
        )}
      {sliderOptions[ESliderOptions.TERM] && (
        <SimulatorItem
          key={ESliderOptions.TERM}
          sliderItemOptions={sliderOptions[ESliderOptions.TERM]}
          disabled={disabled}
          useStore={useStore}
        />
      )}
      {sliderOptions[ESliderOptions.ADVANCED_PAYMENTS] && (
        <SimulatorItem
          key={ESliderOptions.ADVANCED_PAYMENTS}
          sliderItemOptions={sliderOptions[ESliderOptions.ADVANCED_PAYMENTS]}
          disabled={disabled}
          useStore={useStore}
        />
      )}
      {sliderOptions[ESliderOptions.FOLLOWING_PAYMENTS] && (
        <SimulatorItem
          key={ESliderOptions.FOLLOWING_PAYMENTS}
          sliderItemOptions={sliderOptions[ESliderOptions.FOLLOWING_PAYMENTS]}
          disabled={disabled}
          useStore={useStore}
        />
      )}
      {offer.showOperatingHours &&
        sliderOptions[ESliderOptions.OPERATING_HOURS] && (
          <SimulatorItem
            key={ESliderOptions.OPERATING_HOURS}
            sliderItemOptions={sliderOptions[ESliderOptions.OPERATING_HOURS]}
            disabled={disabled}
            useStore={useStore}
          />
        )}
      {sliderOptions[ESliderOptions.BALLOON] && (
        <SimulatorItem
          key={ESliderOptions.BALLOON}
          sliderItemOptions={sliderOptions[ESliderOptions.BALLOON]}
          disabled={disabled}
          useStore={useStore}
        />
      )}
      {sliderOptions[ESliderOptions.RESIDUAL_VALUE] && (
        <SimulatorItem
          key={ESliderOptions.RESIDUAL_VALUE}
          sliderItemOptions={sliderOptions[ESliderOptions.RESIDUAL_VALUE]}
          disabled={disabled}
          useStore={useStore}
        />
      )}
      {offer.upsellingEnabled && (
        <SimulatorUpselling
          title={offer.upsellingTitle}
          productName={offer.upsellingProductName}
          description={offer.upsellingDescription}
          descriptionTitle={offer.upsellingDescriptionTitle}
          isActive={upselling}
          onToggleUpselling={active =>
            onAdditionalOptionsChangeCallback(
              EAdditionalOptions.UPSELLING,
              active,
            )
          }
        />
      )}
    </section>
  );
};

Simulator.defaultProps = {
  disabled: false,
  offer: undefined,
  onCloseSimulation: null,
  cbyo: false,
};

export default Simulator;
