import { z } from 'zod';

import EPaymentForms from 'types/enums/EPaymentForms';

import { IApiBalloonPeriod } from './IOffer';

interface IndexToNumber {
  [index: number]: number;
}
interface IndexToIndexToNumber {
  [index: number]: IndexToNumber;
}

export interface ICalculatorVariables {
  amount: number;
  balloon: number;
  downpayment: number;
  futureValue: number;
  paymentsNumber: number;
  rate: number;
  ratePerPeriod: number;
  rv: number;
  upselling: boolean;
}

export interface ICalculatorOptions {
  _r_v_period?: IndexToIndexToNumber;
  balloon_amount?: number;
  balloon_period?: IApiBalloonPeriod;
  country?: {
    calculate_rv_with_net: boolean;
    code: string;
    currency: string;
    disclaimer: string;
    id: number;
    name: string;
    newsletter_subscribe: boolean;
    show_a_p_r: boolean;
  };
  first_payment_advance?: boolean;
  lease_rates: IndexToNumber;
  loan_opening_fee: number;
  loan_opening_fee_percent: number;
  loan_opening_fee_type: string;
  loan_rates: IndexToNumber;
  residual_value: number;
  upselling_rates: IndexToNumber;
}

export interface ICalculatorValues {
  advancePayments?: number;
  amount: number;
  balloon: number;
  date: Date;
  downpayment: number;
  followingPayments?: number;
  frequency: EPaymentForms;
  hours: number;
  leasing: boolean;
  rv: number;
  term: number;
  upselling: boolean;
}

export const ZCalculatorResult = z.object({
  advancePayment: z.number().optional(),
  amount: z.number().optional(),
  APR: z.number().optional(),
  balloon: z.number(),
  downpayment: z.number(),
  hours: z.number(),
  hoursBalloon: z.number(),
  openingFee: z.number(),
  openingFeeType: z.string(),
  paymentsNumber: z.number(),
  rate: z.number(),
  residualValue: z.number(),
  result: z.number(),
  showBalloon: z.number(),
  showRV: z.number(),
});

export type ICalculatorResult = z.infer<typeof ZCalculatorResult>;

export type CalculatorFrequencyToIndexType = {
  [key in EPaymentForms]: number;
};
