import React from 'react';

interface IImage {
  imageMobile?: string;
  imageTablet?: string;
  altText?: string;
  imageDesktop: string;
}

const Image = ({ imageMobile, imageTablet, imageDesktop, altText }: IImage) => (
  <picture>
    <source
      srcSet={`/assets/${imageDesktop}`}
      media='(min-width: 1024px)' // see tailwind config: screens.lg
    />
    {imageTablet && (
      <source
        srcSet={`/assets/${imageTablet}`}
        media='(min-width: 768px)' // see tailwind config: screens.md
      />
    )}
    {imageMobile && <source srcSet={`/assets/${imageMobile}`} />}
    <img className='w-full' alt={altText} src={`/assets/${imageDesktop}`} />
  </picture>
);

Image.defaultProps = {
  imageMobile: undefined,
  imageTablet: undefined,
  altText: '',
};

export default Image;
