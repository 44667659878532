import { IApiOffer } from 'types/interfaces/IOffer';
import { SimulatorStateBase } from 'types/interfaces/ISimulatorState';

// Todo this whole block just makes so little sense :( Refactor once we have reliable tests
export default (
  rvPeriod: IApiOffer['_r_v_period'],
  term: SimulatorStateBase['term'],
): boolean => {
  if (rvPeriod && term) {
    // eslint-disable-next-line no-restricted-syntax
    for (const rvKey in rvPeriod[term]) {
      if (parseFloat(`${rvPeriod[term][rvKey]}`)) {
        return true;
      }
    }
  }
  return false;
};
