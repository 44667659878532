export default (params: {
  options?: {
    resetDataLayer?: boolean;
    pageNameAddOfferName?: boolean;
    pageNameWithoutPageCategoryAndOfferName?: boolean;
  };
  data: {
    event: string;
    breadCrumbs: string[];
    locale: string;
    pageCategory: string;
    offerName?: string;
    country: string;
    language: string;
  };
}) => {
  const { options, data } = params;
  const adobeDataLayer = (window as any).adobeDataLayer as {}[];

  if (options && options.resetDataLayer) {
    adobeDataLayer.length = 0;
  }

  const pageData: {
    offerName?: string;
    breadCrumbs: string[];
    locale: string;
    pageName: string;
    pageCategory: string;
    siteCountry: string;
    siteLanguage: string;
    appName: string;
  } = {
    breadCrumbs: data.breadCrumbs,
    locale: data.locale,
    pageName: `${data.locale} : finance : financial calculator : ${data.pageCategory}`,
    pageCategory: data.pageCategory,
    siteCountry: data.country,
    siteLanguage: data.language,
    appName: 'jdfc',
  };

  if (options && options.pageNameAddOfferName) {
    pageData.pageName = `${data.locale} : finance : financial calculator : ${data.pageCategory} : ${data.offerName}`;
  }

  if (options && options.pageNameWithoutPageCategoryAndOfferName) {
    pageData.pageName = `${data.locale} : finance : financial calculator`;
  }

  if (data.offerName) {
    pageData.offerName = data.offerName;
  }

  const optanonActiveGroups =
    ((window as any).OptanonActiveGroups as any) ?? '';

  adobeDataLayer.push({
    event: data.event,
    consent: optanonActiveGroups,
    page: pageData,
  });
};
