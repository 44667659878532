import { Button, Typography } from '@deere/fuel-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWizard } from 'react-use-wizard';

import DealerLocator from 'components/DealerLocator/DealerLocator';

import { IFormtoolData } from 'types/IFormtoolData';
import { IDealer } from 'types/interfaces/IDealer';

interface IChooseContact {
  onNextStep: (
    stepData: Pick<
      IFormtoolData,
      | 'dealer_address'
      | 'dealer_company'
      | 'dealer_email'
      | 'dealer_locationid'
      | 'dealer_phone'
      | 'dealer_street'
      | 'dealer_website'
    >,
    isLastUpdate?: boolean,
  ) => void;
}

const ChooseContact = ({ onNextStep }: IChooseContact) => {
  const { t } = useTranslation();
  const { previousStep } = useWizard();

  const onDealerSelected = (dealer: IDealer) => {
    onNextStep(
      {
        dealer_address: dealer.formattedAddress.join(' '),
        dealer_company: dealer.locationName,
        dealer_email: dealer.contactDetail.email,
        dealer_locationid: dealer.locationId.toString(),
        dealer_phone: dealer.contactDetail.phone,
        dealer_street: dealer.formattedAddress[0],
        dealer_website: dealer.contactDetail.website,
      },
      // This is the last update to the collected form data.
      // After merging the dealer infos, the form tool request should be sent.
      true,
    );
  };

  return (
    <div className='p-8'>
      <Typography size='2xl' component='p' fontWeight='bold' gutterBottom>
        {t('CONSULTATION.STEPS.CHOOSE_CONTACT.STEP_TITLE')}
      </Typography>
      <DealerLocator onDealerSelected={onDealerSelected} />
      {/* Go step back */}
      <div className='mt-8'>
        <Button variant='primary' onClick={previousStep}>
          {t('CONSULTATION.BACK')}
        </Button>
      </div>
    </div>
  );
};

export default ChooseContact;
