const handleRangeSliderIntervalLabels = (element: Element) => {
  const sliderRoot = element.querySelector<HTMLElement>('.MuiSlider-root');
  const leftInterval = element.querySelector<HTMLElement>(
    '.MuiSlider-markLabel[data-index="0"]',
  );
  const rightInterval = element.querySelector<HTMLElement>(
    '.MuiSlider-markLabel[data-index="1"]',
  );
  const sliderThumb = element.querySelector<HTMLElement>(
    '.MuiSlider-valueLabel',
  );
  const sliderThumbLabel = element.querySelector<HTMLElement>(
    '.MuiSlider-valueLabelLabel',
  );

  // hide left label when the slider thumb overlaps or show again if it is not the case
  if (
    leftInterval &&
    sliderThumb &&
    leftInterval.getBoundingClientRect().right >
      sliderThumb.getBoundingClientRect().left
  ) {
    leftInterval.classList.add('hide-slider-label');
  } else if (leftInterval) {
    leftInterval.classList.remove('hide-slider-label');
  }

  // hide right label when the slider thumb overlaps or show again if it is not the case
  if (
    rightInterval &&
    sliderThumb &&
    rightInterval.getBoundingClientRect().left <
      sliderThumb.getBoundingClientRect().right
  ) {
    rightInterval.classList.add('hide-slider-label');
  } else if (rightInterval) {
    rightInterval.classList.remove('hide-slider-label');
  }

  // move the label left with the same value as its margin so that does not exceed the slider boundaries when reaches left side
  // set left to auto if not the above case
  if (sliderThumb) {
    sliderThumb.classList.add('set-left-slider-label');
  }
  if (
    sliderRoot &&
    sliderThumb &&
    sliderThumbLabel &&
    sliderRoot.getBoundingClientRect().left >
      sliderThumbLabel.getBoundingClientRect().left
  ) {
    sliderThumb.classList.add('set-left-slider-label');
  } else if (sliderThumb) {
    sliderThumb.classList.remove('set-left-slider-label');
  }

  // move the label right with the same value as its margin so that does not exceed the slider boundaries when reaches right side
  // set right to auto if not the above case
  if (sliderThumb) {
    sliderThumb.classList.add('set-right-slider-label');
  }
  if (
    sliderRoot &&
    sliderThumb &&
    sliderThumbLabel &&
    sliderRoot.getBoundingClientRect().right <
      sliderThumbLabel.getBoundingClientRect().right
  ) {
    sliderThumb.classList.add('set-right-slider-label');
  } else if (sliderThumb) {
    sliderThumb.classList.remove('set-right-slider-label');
  }
};

export default handleRangeSliderIntervalLabels;
