import { CircularProgress } from '@deere/fuel-react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchDealerResults, fetchGeocodeByLatLng } from 'api/api';

import { useCountry } from 'contexts/CountryContext';

import EIndustry from 'types/enums/EIndustry';
import { IDealer } from 'types/interfaces/IDealer';

import filterMapsApiAddressComponentsByType from 'utils/filterMapsApiAddressComponentsByType';

import DealerSelectorIndustryChooser from './DealerSelectorIndustryChooser';
import DealerSelectorResults from './DealerSelectorResults';
import DealerSelectorSearch from './DealerSelectorSearch';

const DEALER_SEARCH_RESULT_COUNT = 10;

interface IDealerSelector {
  searchRadius: number;
  onLatLngChange: (latLng: google.maps.LatLngLiteral) => void;
  onDealersChange: (dealers: IDealer[]) => void;
  onDealerSelected: (dealer: IDealer) => void;
}

const DealerSelector = ({
  searchRadius,
  onLatLngChange,
  onDealersChange,
  onDealerSelected,
}: IDealerSelector) => {
  const { t } = useTranslation();
  const country = useCountry();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [dealers, setDealers] = useState<IDealer[]>([]);
  const [industry, setIndustry] = useState<null | EIndustry>(null);
  const [latLng, setLatLng] = useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });
  // To control the <Autocomplete /> component in <DealerSelectorSearch />,
  //  we need to store the autocomplete ref in a custom ref (`createRef()`)
  //  which doesn't get reset (`useRef()`) once the component re-renders.
  const { current: autocompleteRef } = useRef(
    React.createRef<HTMLInputElement>(),
  );

  // Everytime the dealers are changing, we pass up the new dealers
  //  to update the dealers on the map
  useEffect(() => onDealersChange(dealers), [dealers]);

  useEffect(() => {
    setError(null);
    setDealers([]);
    if (industry) {
      setLoading(true);
      fetchDealerResults({
        country,
        ...latLng,
        radius: searchRadius,
        search: {
          industry,
          resultCount: DEALER_SEARCH_RESULT_COUNT,
        },
      })
        .then(dealersRes => {
          // reset potential previous error
          setError(null);
          setDealers(dealersRes);
        })
        .catch(() =>
          setError(t('DEALER_LOCATOR.SELECTOR.ERROR.FETCHING_DEALER_DATA')),
        )
        .finally(() => {
          setLoading(false);
        });
    }
  }, [industry]);

  // find proper address after getting lat lng from new place
  useEffect(() => {
    if (latLng.lat && latLng.lng) {
      setDealers([]);
      setIndustry(null);
      fetchGeocodeByLatLng(latLng).then(locations => {
        if (locations.length && autocompleteRef.current) {
          autocompleteRef.current.value = filterMapsApiAddressComponentsByType(
            locations[0].address_components,
          ).join(' ');
          // if location was found, update map via DealerLocator component
          onLatLngChange(latLng);
        } else {
          setError(
            !locations.length
              ? t('DEALER_LOCATOR.SELECTOR.ERROR.NO_LOCATIONS')
              : // This case only happens if something with the ref crashed.
                //  User doesn't have to know about this ;)
                t('DEALER_LOCATOR.SELECTOR.ERROR.UNKNOWN'),
          );
        }
      });
    }
  }, [latLng]);

  return (
    <section className='px-3' style={{ flexBasis: '100%' }}>
      <section className='py-3'>
        <DealerSelectorSearch
          autocompleteRef={autocompleteRef}
          onLatLngChange={newLatLng => setLatLng(newLatLng)}
        />
      </section>
      {latLng.lat > 0 && latLng.lng > 0 && (
        <section className='py-3'>
          <DealerSelectorIndustryChooser
            currentIndustry={industry}
            onIndustryChange={newIndustry => setIndustry(newIndustry)}
          />
        </section>
      )}
      {loading ? (
        <CircularProgress size='lg' variant='indeterminate' />
      ) : (
        <section className='py-3'>
          {industry && (
            <DealerSelectorResults
              dealers={dealers}
              onDealerSelected={onDealerSelected}
            />
          )}
        </section>
      )}
      {error && (
        <section>
          <span style={{ color: 'red' }}>{error}</span>
        </section>
      )}
    </section>
  );
};

export default DealerSelector;
