import React from 'react';

import { IApiOffer } from 'types/interfaces/IOffer';

import formatNumber from 'utils/formatNumber';

const FinancingLabeledPrice = ({
  value,
  countryCode,
  currency,
  title,
}: {
  value: number | undefined;
  countryCode: IApiOffer['country']['code'];
  currency: IApiOffer['country']['currency'];
  title: string;
}) => (
  <div>
    <strong>
      {formatNumber(value, countryCode, {
        withCurrency: true,
        currency,
      })}
    </strong>
    <h3 className='opacity-40' dangerouslySetInnerHTML={{ __html: title }} />
  </div>
);

export default FinancingLabeledPrice;
