import { Slider } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { RangeSliderProps } from 'types/interfaces/ISimulatorState';

import './RangeSlider.css';
import handleRangeSliderIntervalLabels from './helper';

const RangeSlider = ({
  value,
  formatValue,
  onChange,
  min,
  max,
  step,
  additionalLabel,
  disabled,
}: RangeSliderProps) => {
  const mainRangeSlider = useRef(null);
  const additionalRangeSlider = useRef(null);
  const [currentValue, setCurrentValue] = useState<number>(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    if (mainRangeSlider.current) {
      handleRangeSliderIntervalLabels(mainRangeSlider.current);
    }
    if (additionalLabel && additionalRangeSlider.current) {
      handleRangeSliderIntervalLabels(additionalRangeSlider.current);
    }
  });

  return (
    <div
      ref={mainRangeSlider}
      className={`w-full py-3 flex flex-wrap justify-between items-center range-slider-wrapper ${
        disabled ? 'range-slider-disabled' : ''
      }`}
    >
      <Slider
        value={currentValue}
        min={min}
        max={max}
        step={step}
        marks={
          min >= max
            ? []
            : [
                { value: min, label: formatValue(min) },
                { value: max, label: formatValue(max) },
              ]
        }
        valueLabelDisplay='on'
        disabled={disabled}
        onChangeCommitted={() => onChange(Number(currentValue))}
        onChange={event =>
          setCurrentValue(Number((event.target as HTMLInputElement).value))
        }
        valueLabelFormat={() => formatValue(currentValue as number)}
      />
      {additionalLabel && (
        <div ref={additionalRangeSlider} className='w-full extra-range-slider'>
          <Slider
            value={currentValue}
            min={min}
            max={max}
            step={step}
            marks={
              min >= max
                ? []
                : [
                    { value: min, label: additionalLabel.formatValue(min) },
                    { value: max, label: additionalLabel.formatValue(max) },
                  ]
            }
            disabled={disabled}
            valueLabelDisplay='on'
            valueLabelFormat={() =>
              `${additionalLabel.formatValue(currentValue as number)}`
            }
          />
        </div>
      )}
    </div>
  );
};

RangeSlider.propTypes = {
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  formatValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  additionalLabel: PropTypes.shape({
    formatValue: PropTypes.func,
  }),
};
RangeSlider.defaultProps = {
  additionalLabel: null,
  disabled: false,
};

export default RangeSlider;
