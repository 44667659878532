// @ts-nocheck
import { Alert, Typography } from '@deere/fuel-react';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';
import { v4 as uuidv4 } from 'uuid';

import ChooseContact from 'components/Consultation/Steps/ChooseContact';
import Confirmation from 'components/Consultation/Steps/Confirmation';
import PersonalInformation from 'components/Consultation/Steps/PersonalInformation';
import WizardHeader from 'components/Consultation/WizardHeader';
import Image from 'components/Image/Image';

import { CountryProvider } from 'contexts/CountryContext';

import { IFormtoolData } from 'types/IFormtoolData';
import ERequestState from 'types/enums/ERequestState';
import EWizardSteps from 'types/enums/EWizardSteps';
import { SimulatorState } from 'types/interfaces/ISimulatorState';
import IWizardSteps from 'types/interfaces/IWizardSteps';

import initializeFormToolData from 'utils/initializeFormToolData';
import isLeadCountry from 'utils/isLeadCountry';
import mapOfferSettingsToURL from 'utils/mapOfferSettingsToURL';
import parseCountryCode from 'utils/parseCountryCode';
import sendFormToolRequest from 'utils/sendFormToolRequest';
import trackData from 'utils/trackData';

import './Consultation.css';

const Consultation = () => {
  const { t } = useTranslation();

  const [formToolData, setFormToolData] = useState<IFormtoolData>(
    initializeFormToolData(),
  );
  const [hasOffer, setHasOffer] = useState<boolean>(false);
  const [confirmationState, setConfirmationState] = useState<ERequestState>(
    ERequestState.LOADING,
  );
  const [consultationAvailable, setConsultationAvailable] = useState(false);

  const { offerId } = useParams<{ offerId: string }>();

  // Check if all mandatory form data has been collected.
  // Trigger form tool request in useEffect then.
  const formDataComplete = useRef<boolean>(false);

  const handleNextStep = (
    stepData: Partial<IFormtoolData>,
    isLastUpdate?: boolean,
  ) => {
    formDataComplete.current = !!isLastUpdate;
    setFormToolData({
      ...formToolData,
      ...stepData,
    });
  };

  const wizardSteps: IWizardSteps[] = [
    {
      component: <PersonalInformation onNextStep={handleNextStep} />,
      headerTitle: 'CONSULTATION.STEPS.PERSONAL_INFORMATION.STEP_TITLE',
      key: EWizardSteps.PERSONAL_INFORMATION,
    },
    {
      component: <ChooseContact onNextStep={handleNextStep} />,
      headerTitle: 'CONSULTATION.STEPS.CHOOSE_CONTACT.STEP_TITLE',
      key: EWizardSteps.CHOOSE_CONTACT,
    },
    {
      component: <Confirmation currentState={confirmationState} />,
      headerTitle: 'CONSULTATION.STEPS.CONFIRMATION.STEP_TITLE',
      key: EWizardSteps.CONFIRMATION,
    },
  ];

  useEffect(() => {
    if (formDataComplete.current) {
      sendFormToolRequest(formToolData)
        .then(() => setConfirmationState(ERequestState.SUCCESS))
        .catch(() => setConfirmationState(ERequestState.ERROR));
    }
  }, [formToolData]);

  useEffect(() => {
    const simulatorRaw = window.localStorage.getItem(`simulation-${offerId}`);
    const simulator: SimulatorState = simulatorRaw
      ? JSON.parse(simulatorRaw)
      : null;
    if (simulator) {
      // the local storage contains more props than the ISimulatorValues
      //  but only these values are needed in this case
      const valuesEncoded = btoa(
        JSON.stringify(mapOfferSettingsToURL(simulator)),
      );
      setHasOffer(true);

      const { locale, language, country } = parseCountryCode(
        simulator.offer.country.code,
      );
      trackData({
        options: {
          resetDataLayer: true,
          pageNameAddOfferName: true,
        },
        data: {
          event: 'Form Loaded',
          pageCategory: 'consultation form',
          locale,
          language,
          country,
          breadCrumbs: [
            // not sure if we have to change this, can be removed?
            'servlet',
            'country=DE',
          ],
          offerName: simulator.offer.title,
        },
      });

      setConsultationAvailable(isLeadCountry(simulator.offer.country.code));
      setFormToolData({
        ...formToolData,
        ...{
          Language: simulator.offer.country.code.substring(0, 2),
          Country: simulator.offer.country.code.slice(-2),
          administration_fee_in_euro: simulator.result.openingFee.toString(),
          advance_payment: simulator.result.advancePayment?.toString() || '',
          balloon_payment_residual_value_in_euro:
            simulator.result.balloon?.toString() || '',
          currency: simulator.offer.country.currency,
          deposit_in_euro: simulator.downPayment?.toString() || '',
          finance_product: simulator.financeType,
          frequency_of_payment: simulator.paymentForm,
          loan_amount: simulator.result.amount?.toString() || '',
          Locale: simulator.offer.country.code,
          net_machine_price: simulator.machinePrice.toString(),
          number_of_following_payments:
            simulator.followingPayments?.toString() || '',
          offer_name: simulator.offer.title,
          // offer_description: simulator.offer.description,
          operating_hours: simulator.operatingHours.toString(),
          rate_in_percen: simulator.result.rate.toString(),
          saved_offer_url: `${window.location.origin}/${offerId}?v=${valuesEncoded}`,
          guid: uuidv4(),
          submission_URL: `${window.location.origin}/${simulator.offer.id}`,
          submission_url: `${window.location.origin}/${simulator.offer.id}`,
          total_monthly: simulator.result.result.toString(),
          total_per_operating_hour: simulator.operatingHours.toString(),
          years: simulator.term?.toString() || '',
        },
      });
    }
  }, []);

  return (
    <>
      <section className='wizard my-3'>
        <section className='mb-8'>
          <Typography size='4xl' fontWeight='bold' component='h1'>
            {t('CONSULTATION.TITLE')}
          </Typography>
        </section>
        <section className='my-3'>
          <Image
            imageDesktop='arrange-consultation-header-desktop.jpg'
            imageTablet='arrange-consultation-header-tablet.jpg'
            imageMobile='arrange-consultation-header-mobile.jpg'
            altText='Consultation mood image'
          />
        </section>
        {hasOffer ? (
          <section className='bg-white mt-3'>
            <Helmet>
              <title>{formToolData.offer_name}</title>
              {/* <meta name='description' content={formToolData.offer_description} /> */}
              {/* <title><Trans
              i18nKey='META.TITLE'
              values={{
                offer: formToolData.offer_name
              }}
            /></title> */}
            </Helmet>
            {consultationAvailable ? (
              <>
                <div className='p-8'>
                  <Typography
                    gutterBottom
                    color='secondary'
                    size='md'
                    component='p'
                  >
                    {t('CONSULTATION.DESCRIPTION')}
                  </Typography>
                </div>
                <CountryProvider value={formToolData.Country}>
                  <Wizard
                    startIndex={0}
                    header={<WizardHeader wizardSteps={wizardSteps} />}
                  >
                    {wizardSteps.map(step => (
                      <div key={step.key}>{step.component}</div>
                    ))}
                  </Wizard>
                </CountryProvider>
              </>
            ) : (
              <div className='p-8'>
                <Alert
                  variant='warning'
                  title={t('CONSULTATION.NOT_AVAILABLE')}
                />
              </div>
            )}
          </section>
        ) : (
          <span>No offer found</span>
        )}
      </section>
    </>
  );
};

export default Consultation;
