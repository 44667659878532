import React from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';

import CbyoHiddenPage from 'pages/CbyoHiddenPage';
import Consultation from 'pages/Consultation';
import Finalize from 'pages/Finalize';
import Offer from 'pages/Offer';
import Offers from 'pages/Offers';

const Routes = () => (
    <Router>
        <Switch>
            <Route path='/consultation/:offerId'>
                <Consultation />
            </Route>
            <Route
                path='/finalize/:offerId'
                render={({ location, match }) => {
                    let hasOfferParam = false;
                    if (location && location.search) {
                        const searchParams = new URLSearchParams(location.search);
                        hasOfferParam = searchParams.has('offer');
                    }
                    if (hasOfferParam)
                        return (
                            <Redirect to={`/${match.params.offerId}${location.search}`} />
                        );
                    return <Finalize />;
                }}
            />
            <Route path='/cbyo'>
                <CbyoHiddenPage />
            </Route>
            <Route path='/offers/:countryCode'>
                <Offers />
            </Route>
            <Route path='/:offerId/:type?'>
                <Offer withTracking />
            </Route>
            <Route path='/'>
                {
                    // TODO: remove this if not applicable
                }
                <Redirect to='/offers/de_DE' />
            </Route>
        </Switch>
    </Router>
);

export default Routes;