// @ts-nocheck
import { Typography } from '@deere/fuel-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { fetchOffers } from 'api/api';

import Loader from 'components/Loader/Loader';
import OfferCard from 'components/OfferCard/OfferCard';

import { IOffer } from 'types/interfaces/IOffer';

import changeLanguage from 'utils/changeLanguage';
import parseCountryCode from 'utils/parseCountryCode';
import trackData from 'utils/trackData';

const Offers = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [offers, setOffers] = useState<IOffer[]>([]);
  const { countryCode } = useParams<{ countryCode: string }>();

  useEffect(() => {
    // set language
    if (countryCode) {
      changeLanguage(countryCode);
    }
    // fetch country specific offers
    fetchOffers(countryCode).then(fetchedOffers => {
      setIsLoading(false);
      setOffers(fetchedOffers);

      const { locale, language, country } = parseCountryCode(countryCode);

      trackData({
        options: {
          resetDataLayer: true,
        },
        data: {
          event: 'Page Loaded',
          pageCategory: 'homepage',
          locale,
          language,
          country,
          breadCrumbs: [
            // not sure if we have to change this, can be removed?
            'servlet',
            'country=US',
          ],
        },
      });
    });
  }, [countryCode]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <section className='grid grid-cols-1 gap-0 gap-y-8 sm:gap-8 sm:grid-cols-2 my-3'>
      <div className='w-full col-span-2'>
        <Typography
          size='4xl'
          component='h1'
          fontWeight='bold'
          gutterBottom
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{ __html: t('JOHN_DEERE_SIMULATOR') }}
        />
      </div>
      {Boolean(offers.length) &&
        offers.map(offer => (
          <OfferCard
            withTracking
            title={offer.title}
            subtitle={offer.subtitle}
            description={offer.description}
            publicationEnd={offer.publicationEnd}
            image={offer.imageResized}
            isLink
            key={offer.id}
            countryCode={offer.country.code}
            offerLink={`/${offer.id}`}
          />
        ))}
      {!offers.length && !isLoading && (
        <div className='my-5'>
          <Typography size='xs' component='p' gutterBottom>
            {t('OFFERS.NORESULTS')}
          </Typography>
        </div>
      )}
      <div className='w-full col-span-2'>
        <Typography
          color='tertiary'
          size='xs'
          component='p'
          gutterBottom
          dangerouslySetInnerHTML={{ __html: t('FOOTER.DISCLAIMER') }}
        />
      </div>
    </section>
  );
};

export default Offers;
