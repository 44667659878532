import { ISimulatorValues } from 'types/interfaces/ISimulator';

const checkQueryParamToken = (search: string): string => {
  const query = new URLSearchParams(search);
  const token = query.get('token');

  if (token === null) {
    return '';
  }
  // set empty token if the data can not be decoded
  try {
    return atob(token);
  } catch (error) {
    return '';
  }
};

const checkQueryParamValues = (
  search: string,
): ISimulatorValues | undefined => {
  const query = new URLSearchParams(search);
  const values = query.get('v');

  if (values === null) {
    return undefined;
  }
  // set empty v if the data can not be decoded
  // can be tested with MTU0aMTU0
  try {
    return JSON.parse(atob(values));
  } catch (error) {
    return undefined;
  }
};

export { checkQueryParamToken, checkQueryParamValues };
