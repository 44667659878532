import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@deere/fuel-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCountry } from 'contexts/CountryContext';

import EIndustry from 'types/enums/EIndustry';

import getIndustryByIndustryId from 'utils/getIndustryByIndustryId';

/**
 * Some countries should display different industries than others.
 * @param countryCode
 */
const getIndustryIdsByCountryCode = (country: string): EIndustry[] => {
  switch (country) {
    case 'GB':
    case 'UK':
      return [
        EIndustry.AGRICULTURE,
        EIndustry.GOLF_AND_SPORTS,
        EIndustry.LAWN_AND_GARDEN,
        EIndustry.FORESTRY,
        EIndustry.LANDSCAPING_AND_GROUND_CARE,
      ];
    case 'LU':
      return [
        EIndustry.AGRICULTURE,
        EIndustry.LAWN_AND_GARDEN,
        EIndustry.COMMERCIAL,
      ];
    case 'LT':
      return [
        EIndustry.AGRICULTURE,
        EIndustry.LAWN_AND_GARDEN,
        EIndustry.FORESTRY,
        EIndustry.COMMERCIAL,
      ];
    default:
      return [
        EIndustry.AGRICULTURE,
        EIndustry.GOLF_AND_SPORTS,
        EIndustry.LAWN_AND_GARDEN,
        EIndustry.FORESTRY,
        EIndustry.COMMERCIAL,
      ];
  }
};

interface IDealerSelectorIndustryChooser {
  currentIndustry: EIndustry | null;
  onIndustryChange: (industry: EIndustry | null) => void;
}

const DealerSelectorIndustryChooser = ({
  currentIndustry,
  onIndustryChange,
}: IDealerSelectorIndustryChooser) => {
  const { t } = useTranslation();
  const country = useCountry();
  return (
    <>
      {currentIndustry ? (
        <section>
          <Typography
            color='secondary'
            size='md'
            component='p'
            fontWeight='bold'
          >
            {t('DEALER_LOCATOR.SELECTOR.INDUSTRY_CHOOSER.INDUSTRY')}
          </Typography>
          <Typography color='secondary' size='md' component='p'>
            {t(getIndustryByIndustryId(currentIndustry))} (
            <Button
              className='py-0 px-1'
              onClick={() => onIndustryChange(null)}
              variant='text'
            >
              {t('DEALER_LOCATOR.SELECTOR.INDUSTRY_CHOOSER.CHANGE')}
            </Button>
            )
          </Typography>
        </section>
      ) : (
        <FormControl component='fieldset'>
          <FormLabel className='w-full' component='legend'>
            <Typography
              color='secondary'
              size='md'
              component='p'
              fontWeight='bold'
            >
              {t('DEALER_LOCATOR.SELECTOR.INDUSTRY_CHOOSER.SELECT_INDUSTRY')}
            </Typography>
          </FormLabel>
          <RadioGroup aria-label='industry' name='industry'>
            {getIndustryIdsByCountryCode(country).map(industryId => (
              <FormControlLabel
                key={industryId}
                value={industryId}
                control={
                  <Radio
                    onClick={evt =>
                      onIndustryChange(
                        Number((evt.target as HTMLInputElement).value),
                      )
                    }
                  />
                }
                label={t(getIndustryByIndustryId(industryId)).toString()}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    </>
  );
};

export default DealerSelectorIndustryChooser;
