import { IFormtoolData } from 'types/IFormtoolData';

export default (): IFormtoolData => ({
  // FROM OFFER
  Language: '',
  Country: '',
  administration_fee_in_euro: '',
  advance_payment: '',
  balloon_payment_residual_value_in_euro: '',
  currency: '',
  deposit_in_euro: '',
  finance_product: '',
  frequency_of_payment: '',
  loan_amount: '',
  Locale: '',
  net_machine_price: '',
  number_of_following_payments: '',
  offer_name: '',
  operating_hours: '',
  rate_in_percen: '',
  saved_offer_url: '',
  guid: '',
  submission_URL: '',
  submission_url: '',
  total_monthly: '',
  total_per_operating_hour: '',
  years: '',
  // FROM PERSONAL INFORMATION (STEP 1)
  customer_city: '',
  customer_company: '',
  email: '',
  firstName: '',
  lastName: '',
  customer_phone: '',
  customer_postcode: '',
  customer_salutation: '',
  customer_street: '',
  // FROM DEALER LOCATOR (STEP 2)
  dealer_address: '',
  dealer_company: '',
  dealer_email: '',
  dealer_locationid: '',
  dealer_phone: '',
  dealer_street: '',
  dealer_website: '',
});
