import { Button, Typography } from '@deere/fuel-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWizard } from 'react-use-wizard';

import { IDealer } from 'types/interfaces/IDealer';

interface IDealerSelectorResultItem {
  dealer: IDealer;
  count: number;
  onDealerSelected: (dealer: IDealer) => void;
}

const DealerSelectorResultItem = ({
  dealer,
  count,
  onDealerSelected,
}: IDealerSelectorResultItem) => {
  const { t } = useTranslation();
  const { nextStep } = useWizard();

  return (
    <div className='sm:flex py-2 border-t-2 border-dark'>
      <div className='relative w-10 flex-shrink-0'>
        {/* Mind: Used as <img />, not as component */}
        {/* as it is reused as Marker in the Map */}
        {/* which requires a URL (see Marker API). */}
        <img
          src='/assets/location-full.svg'
          alt='marker icon'
          className='w-full text-jd-yellow '
        />
        <div className='absolute top-1 text-sm w-full text-center'>
          <span>{count}</span>
        </div>
      </div>
      <div style={{ flexBasis: '100%' }}>
        <Typography color='secondary' size='lg' component='p'>
          {dealer.locationName}
        </Typography>
        {dealer.formattedAddress.map(addressPart => (
          <Typography
            key={addressPart}
            color='secondary'
            size='md'
            component='p'
          >
            {addressPart}
          </Typography>
        ))}
        <Typography color='secondary' size='md' component='p'>
          {dealer.contactDetail.phone}
        </Typography>
      </div>
      <div className='sm:flex flex-wrap'>
        <Typography
          color='secondary'
          size='md'
          component='p'
          className='w-full sm:text-right'
        >
          {Number(dealer.distance).toFixed(2)}
          {t('DEALER_LOCATOR.SELECTOR.RESULTS.KILOMETER_UNIT')}
        </Typography>
        <Button
          className='flex-grow align-self-end justify-end h-10'
          variant='primary'
          onClick={() => {
            onDealerSelected(dealer);
            nextStep();
          }}
        >
          {t('DEALER_LOCATOR.SELECTOR.RESULTS.SELECT_DEALER')}
        </Button>
      </div>
    </div>
  );
};

export default DealerSelectorResultItem;
