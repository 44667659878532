export default (
  value: string | number | undefined,
  offerLanguage: string,
  options?: {
    withCurrency?: boolean;
    maximumFractionDigits?: number;
    minimumFractionDigits?: number;
    currency?: string;
  },
) => {
  let language = offerLanguage.replace('_', '-');
  // language dependent changes, see #109
  if (language === 'es-ES') {
    // set to "de-DE" to enforce thousands separators / grouping even for 4 digit numbers
    language = 'de-DE';
  }

  return new Intl.NumberFormat(language, {
    style: options?.withCurrency ? 'currency' : 'decimal',
    currency: options?.currency || 'EUR',
    minimumFractionDigits: Object.prototype.hasOwnProperty.call(
      options,
      'minimumFractionDigits',
    )
      ? options?.minimumFractionDigits
      : 2,
    maximumFractionDigits: Object.prototype.hasOwnProperty.call(
      options,
      'maximumFractionDigits',
    )
      ? options?.maximumFractionDigits
      : 2,
  }).format(Number(value || 0));
};
