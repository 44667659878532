import { IApiOffer, IOffer } from 'types/interfaces/IOffer';

import getMachinePriceMaxForCbyo from 'utils/getMachinePriceMaxForCbyo';
import getMachinePriceMinForCbyo from 'utils/getMachinePriceMinForCbyo';

export default (offer: IApiOffer): IOffer => {
  // machinePriceMin and machinePriceMax is updated on CBYO offers with 50% of the cbyoValue
  const cbyoValue = Number(localStorage.getItem('cbyoValue'));
  let machinePriceMin = offer.machine_price_min;
  let machinePriceMax = offer.machine_price_max;

  if (cbyoValue) {
    machinePriceMin = getMachinePriceMinForCbyo(cbyoValue, machinePriceMin);
    machinePriceMax = getMachinePriceMaxForCbyo(cbyoValue, machinePriceMax);
  }

  return {
    balloonAmountDefault: offer.balloon_amount_default,
    // @Todo remove max min
    balloonAmountMax: offer.balloon_amount_max,
    balloonAmountMin: offer.balloon_amount_min,
    balloonPeriod: offer.balloon_period,
    country: {
      calculateRvWithNet: offer.country.calculate_rv_with_net,
      code: offer.country.code,
      currency: offer.country.currency,
      disclaimer: offer.country.disclaimer,
      id: offer.country.id,
      // There is additional first_payment_advance per country
      // that gets merged with the top level first_payment_advance:
      // offer.country.first_payment_advance
      // make sure to also adapt the interfaces once it should be re-added.
      name: offer.country.name,
      newsletterSubscribe: offer.country.newsletter_subscribe,
      newsletterSubscribeLink: offer.country.newsletter_subscribe_link,
      showAPR: offer.country.show_a_p_r,
    },
    description: offer.description,
    disclaimer: offer.disclaimer,
    downPaymentMax: offer.down_payment_max,
    downPaymentMin: offer.down_payment_min,
    durationMax: offer.duration_max,
    durationMin: offer.duration_min,
    featured: offer.featured,
    financeTypes: offer.finance_types,
    firstPaymentAdvance: offer.first_payment_advance,
    id: offer.id,
    image: offer.image,
    imageResized: offer.image_resized,
    showInterestRate: offer.show_interest_rate,
    allowUserPreferenceForInterestRate:
      offer.allow_user_preference_for_interest_rate,
    interestRateMin: offer.interest_rate_min || 0,
    interestRateMax: offer.interest_rate_max || 0,
    ...(offer.interest_based_on_down_payment && {
      interestBasedOnDownPayment: {
        rates: offer.interest_based_on_down_payment.rates,
        ...(offer.interest_based_on_down_payment.interest_based_balloon && {
          interestBasedBalloon: {
            balloonValuesPerYear:
              offer.interest_based_on_down_payment.interest_based_balloon
                .balloon_values_per_year,
            machines:
              offer.interest_based_on_down_payment.interest_based_balloon
                .machines,
          },
        }),
      },
    }),
    // @todo check: This is sometimes not set by backend
    //  Check if this should be set to the standard values (this.options) in calculator
    //  See also ZApiOffer and Offer.tsx (`fetchSingleOffer`)
    leaseRates: offer.lease_rates,
    loanOpeningFee: offer.loan_opening_fee,
    loanOpeningFeePercent: offer.loan_opening_fee_percent,
    loanOpeningFeeType: offer.loan_opening_fee_type,
    // @todo check: This is sometimes not set by backend
    //  Check if this should be set to the standard values (this.options) in calculator
    //  See also ZApiOffer and Offer.tsx (`fetchSingleOffer`)
    loanRates: offer.loan_rates,
    machinePriceMax,
    machinePriceMin,
    operatingHoursLabel: offer.operating_hours_label,
    operatingHoursMax: offer.operating_hours_max,
    operatingHoursMin: offer.operating_hours_min,
    paymentForms: offer.payment_forms,
    priority: offer.priority,
    // publication_end and publication_start are not defined on CBYO Fallback offer
    ...(offer.publication_end && {
      publicationEnd: new Date(offer.publication_end),
    }),
    ...(offer.publication_start && {
      publicationStart: new Date(offer.publication_start),
    }),
    residualValueDefault: offer.residual_value_default,
    residualValueMax: offer.residual_value_max,
    residualValueMin: offer.residual_value_min,
    showOperatingHours: !!offer.show_operating_hours,
    residualValuePeriod: offer._r_v_period, // eslint-disable-line
    status: offer.status,
    subtitle: offer.subtitle,
    title: offer.title,
    upsellingDescriptionTitle: offer.upselling_description_title,
    upsellingDescription: offer.upselling_description,
    upsellingEnabled: offer.upselling_enabled,
    upsellingProductName: offer.upselling_product_name,
    upsellingRates: offer.upselling_rates,
    upsellingTitle: offer.upselling_title,
    visible: offer.visible,
  };
};
