import { useState } from 'react';
import { storeValidationError } from 'store';
import { ZodFirstPartySchemaTypes } from 'zod/lib/types';

const useLocalStorage = <T>(
  key: string,
  zodType?: ZodFirstPartySchemaTypes,
  initialValue?: string | object,
): [T, Function] => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);

      if (zodType && initialValue) {
        const zodParsed = zodType.safeParse(initialValue);
        if (!zodParsed.success) {
          storeValidationError(key, zodParsed.error.issues);
        }
      }
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that
  //  persists the new value to localStorage.
  const setValue = (value: Function | string | object) => {
    try {
      if (zodType) {
        const zodParsed = zodType.safeParse(value);
        if (!zodParsed.success) {
          storeValidationError(key, zodParsed.error.issues);
        }
      }
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };
  return [storedValue, setValue];
};
export default useLocalStorage;
